import type { DeviceSettings, UserSettings } from '@/services/types';
import { DEFAULT_USER_SETTINGS } from '@/utils/settings';
import { computed, ComputedRef, customRef, del, Ref, ref, set, watch, type WatchOptions } from 'vue';
import { type Fn, watchDebounced } from '@vueuse/core';
import { defineStore } from 'pinia';
import equal from 'fast-deep-equal';
import { getFromUserStorage, saveToUserStorage } from '@/services';
import { DeepPartial } from 'varos-connect-shared-ts/UtilityTypes';
import merge from 'deepmerge';

export function useUserSettings (uncommitted: Ref<Partial<UserSettings>>, stored: Ref<UserSettings|undefined>): ComputedRef<UserSettings> {
    function calculateValue (): UserSettings {
        const uncommittedValues = Object.fromEntries(Object.entries(uncommitted.value).filter(([, val]) => val !== undefined));
        return { ...DEFAULT_USER_SETTINGS, ...(stored.value ?? {}), ...uncommittedValues };
    }

    let track: Fn;
    let trigger: Fn;
    let value = calculateValue();
    const dirty = ref(true);

    watch(uncommitted, () => {
        dirty.value = true;
        trigger();
    }, { deep: true, flush: 'sync' });

    watch(stored, () => {
        dirty.value = true;
        trigger();
    }, { flush: 'sync' });

    return customRef<UserSettings>((_track, _trigger) => {
        track = _track;
        trigger = _trigger;

        return {
            set () { /** intentionally empty, because this is supposed to be a computed ref */ },
            get () {
                if (dirty.value) {
                    value = calculateValue();
                    dirty.value = false;
                }
                track();
                return value;
            }
        };
    }) as ComputedRef<UserSettings>;
}

export const useSettingsStore = defineStore('settings', () => {
    const storedUserSettings = ref<UserSettings>();
    const uncommittedUserSettings = ref<Partial<UserSettings>>({ });
    const userSettings = useUserSettings(uncommittedUserSettings, storedUserSettings);
    const dirtyUserSettings = computed(() => Object.entries(uncommittedUserSettings.value).filter(([, val]) => val !== undefined).map(([key]) => key));
    const deviceSettings = ref<DeviceSettings>();

    async function fetchSettings (): Promise<void> {
        let stored = await getFromUserStorage<DeepPartial<UserSettings>>('user_settings.json');
        if (stored === undefined) stored = {};
        storedUserSettings.value = structuredClone(merge(DEFAULT_USER_SETTINGS as UserSettings, stored));
        // TODO: Set deviceSettings
    }

    async function updateUserSettings (settings: UserSettings): Promise<void> {
        await saveToUserStorage('user_settings.json', settings);
        // TODO: Handle errors
    }

    const setupPromise = ref<Promise<void>>(fetchSettings());

    watchDebounced([uncommittedUserSettings, storedUserSettings], async () => {
        if (storedUserSettings.value === undefined) return;
        const fields = Object.entries(uncommittedUserSettings.value).filter(([, v]) => v !== undefined);

        if (fields.length === 0) return;

        // update stored settings
        const settingsToStore: UserSettings = { ...storedUserSettings.value, ...Object.fromEntries(fields) };
        storedUserSettings.value = settingsToStore;
        await updateUserSettings(settingsToStore);

        // remove any field from uncommitted entries, that is equal to new stored settings
        const remainingUncommittedEntries =
            Object.entries(uncommittedUserSettings.value)
                .filter(<T extends keyof UserSettings>([key, v]: [string, Partial<UserSettings>[keyof UserSettings]]) => v !== undefined && !equal(v, settingsToStore[key as T]));
        uncommittedUserSettings.value = Object.fromEntries(remainingUncommittedEntries);
    }, { deep: true, debounce: 2000 } as WatchOptions & { debounce: number });

    function getUserSetting<T extends keyof UserSettings> (key: T): UserSettings[T];
    function getUserSetting<T extends keyof UserSettings> (key: T, fallbackToDefault: true): UserSettings[T];
    function getUserSetting<T extends keyof UserSettings> (key: T, fallbackToDefault: false): UserSettings[T]|undefined;
    function getUserSetting<T extends keyof UserSettings> (key: T, fallbackToDefault = true): UserSettings[T]|undefined {
        if (fallbackToDefault) return userSettings.value[key] ?? DEFAULT_USER_SETTINGS[key];
        return userSettings.value[key];
    }

    function setUserSetting<T extends keyof UserSettings> (key: T, value: UserSettings[T]|undefined) {
        if (value === storedUserSettings.value?.[key]) {
            del(uncommittedUserSettings.value, key);
        } else {
            set(uncommittedUserSettings.value, key, value);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function setDeviceSetting<T extends keyof DeviceSettings> (key: T, value: DeviceSettings[T]|undefined) {
        // TODO: Implement
        // userSettings[key] = value;
    }

    return {
        userSettings,
        dirtyUserSettings,
        deviceSettings,
        setupPromise,
        getUserSetting,
        setUserSetting,
        setDeviceSetting
    };
});
