import { ModulationStandard as ModStd, ModulationScheme, MeasuredValue, LockedChannel, ModulationStandard, SignalInfoMap } from 'varos-connect-shared-ts';

export enum Quality {
    Good = 'good',
    Warning = 'warning',
    Danger = 'danger'
}

type Channel<T extends ModulationStandard = ModulationStandard> = Pick<LockedChannel<T>, 'mod_standard'|'mod_scheme'|'signal_info'>;

function isModStdChannel<M extends ModStd> (channel: Channel|undefined, stds: M[]): channel is Channel<M> {
    return channel !== undefined && stds.includes(channel.mod_standard as M);
}

export default class MeasurementQualityLimitsHelper {
    public static getMeasurementQuality (value: number, type: MeasuredValue, channel?: Channel): Quality|undefined {
        switch (type) {
        case MeasuredValue.Level:
            return this.getLevelQuality(value, channel);
        case MeasuredValue.MER:
            return this.getMERQuality(value, channel);
        case MeasuredValue.NoiseMargin:
            return this.getNoiseMarginQuality(value);
        case MeasuredValue.PacketErrors:
            return this.getPacketErrorsQuality(value);
        case MeasuredValue.PER:
            return this.getPERQuality(value);
        case MeasuredValue.PostBER:
            return this.getPostBERQuality(value, channel);
        case MeasuredValue.PreBER:
            return this.getPreBERQuality(value);
        }
    }

    private static getLevelQuality (value: number, channel?: Channel): Quality|undefined {
        let min: number|undefined;
        let max: number|undefined;

        if (channel === undefined) return undefined;

        if (isModStdChannel(channel, [ModStd.DVB_S, ModStd.DVB_S2, ModStd.DVB_S2X])) {
            // SATELLITE
            [min, max] = [47, 70];
        } else if (isModStdChannel(channel, [ModStd.DVB_C, ModStd.DOCSIS])) {
            // CABLE
            switch (channel.mod_scheme) {
            case ModulationScheme.QAM_16:
            case ModulationScheme.QAM_32:
            case ModulationScheme.QAM_64:
                [min, max] = [53, 63];
                break;
            case ModulationScheme.QAM_128:
            case ModulationScheme.QAM_256:
                [min, max] = [59, 69];
                break;
            case ModulationScheme.OFDM:
                // TODO: Calculate quality for OFDM channels
                break;
            }
        } else if (isModStdChannel(channel, [ModStd.DAB])) {
            // RADIO
            [min, max] = [36, 70];
        } else if (isModStdChannel(channel, [ModStd.DVB_T, ModStd.DVB_T2])) {
            // ANTENNA
            switch (channel.signal_info.subcarrier_mod_scheme) {
            case ModulationScheme.QAM_16:
                [min, max] = [36, 70];
                break;
            case ModulationScheme.QAM_64:
                [min, max] = [45, 70];
                break;
            case ModulationScheme.QAM_256:
                [min, max] = [50, 70];
                break;
            }
        }

        if (min === undefined) return undefined;
        if (value < min) return Quality.Danger;
        if (value < min + 3) return Quality.Warning;

        if (max === undefined) return undefined;
        if (value > max) return Quality.Danger;
        if (value > max - 3) return Quality.Warning;

        return Quality.Good;
    }

    private static getPreBERQuality (value: number): Quality|undefined {
        if (value < 1e-4) return Quality.Good;
        if (value < 1e-3) return Quality.Warning;

        return Quality.Danger;
    }

    private static getPostBERQuality (value: number, channel?: Channel): Quality|undefined {
        if (isModStdChannel(channel, [ModStd.DVB_T2])) {
            if (value < 0.5e-7) return Quality.Good;
            if (value < 1e-7) return Quality.Warning;
            return Quality.Danger;
        }

        if (value < 1e-7) return Quality.Good;
        if (value < 1e-6) return Quality.Warning;
        return Quality.Danger;
    }

    private static getNoiseMarginQuality (value: number): Quality|undefined {
        if (value <= 0) return Quality.Danger;
        if (value < 4) return Quality.Warning;
        return Quality.Good;
    }

    private static getPacketErrorsQuality (value: number): Quality|undefined {
        return value > 0 ? Quality.Danger : Quality.Good;
    }

    private static getPERQuality (value: number): Quality|undefined {
        if (value <= 1.00e-6) return Quality.Good;

        return Quality.Danger;
    }

    private static getMERQuality (value: number, channel?: Channel): Quality|undefined {
        type FEC = SignalInfoMap[ModulationStandard.DVB_T]['fec'];

        function selectByFEC (fec: FEC, values: { [f: FEC]: number|undefined }): number|undefined {
            return values[fec];
        }

        let limit: number|undefined;

        if (isModStdChannel(channel, [ModStd.DVB_C, ModStd.DOCSIS])) {
            switch (channel.mod_scheme) {
            case ModulationScheme.QAM_16:
                limit = 17.0;
                break;
            case ModulationScheme.QAM_32:
                limit = 20.0;
                break;
            case ModulationScheme.QAM_64:
                limit = 23.0;
                break;
            case ModulationScheme.QAM_128:
                limit = 26.0;
                break;
            case ModulationScheme.QAM_256:
                limit = 29.0;
                break;
            case ModulationScheme.OFDM:
                // TODO: Calculate quality for OFDM channels
                break;
            }
        } else if (isModStdChannel(channel, [ModStd.DVB_T])) {
            switch (channel.signal_info.subcarrier_mod_scheme) {
            case ModulationScheme.QAM_64:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 16.5,
                    '2/3': 18.7,
                    '3/4': 20.2,
                    '5/6': 21.6,
                    '7/8': 22.5
                });
                break;
            case ModulationScheme.QAM_16:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 10.8,
                    '2/3': 13.1,
                    '3/4': 14.6,
                    '5/6': 15.6,
                    '7/8': 16.0
                });
                break;
            case ModulationScheme.QPSK:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 5.1,
                    '2/3': 6.9,
                    '3/4': 7.9,
                    '5/6': 8.9,
                    '7/8': 9.7
                });
                break;
            }
        } else if (isModStdChannel(channel, [ModStd.DVB_T2])) {
            switch (channel.signal_info.subcarrier_mod_scheme) {
            case ModulationScheme.QAM_256:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 17.0,
                    '2/3': 20.8,
                    '3/4': 22.9,
                    '3/5': 19.4,
                    '4/5': 24.3,
                    '5/6': 25.1
                });
                break;
            case ModulationScheme.QAM_64:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 13.0,
                    '2/3': 16.2,
                    '3/4': 17.7,
                    '3/5': 14.8,
                    '4/5': 18.7,
                    '5/6': 19.4
                });
                break;
            case ModulationScheme.QAM_16:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 8.7,
                    '2/3': 11.4,
                    '3/4': 12.5,
                    '3/5': 10.1,
                    '4/5': 13.3,
                    '5/6': 13.8
                });
                break;
            case ModulationScheme.QPSK:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/2': 3.5,
                    '2/3': 5.6,
                    '3/4': 6.6,
                    '3/5': 4.7,
                    '4/5': 7.2,
                    '5/6': 7.7
                });
                break;
            }
        } else if (isModStdChannel(channel, [ModStd.DVB_S])) {
            limit = selectByFEC(channel.signal_info.fec, {
                '1/2': 5.0,
                '2/3': 6.0,
                '3/4': 7.0,
                '5/6': 8.0,
                '6/7': 8.5,
                '7/8': 9.0
            });
        } else if (isModStdChannel(channel, [ModStd.DVB_S2, ModStd.DVB_S2X])) {
            switch (channel.mod_scheme) {
            case ModulationScheme.QPSK:
                limit = selectByFEC(channel.signal_info.fec, {
                    '1/4': 1.2,
                    '1/3': 2.4,
                    '2/5': 2.9,
                    '1/2': 3.9,
                    '3/5': 4.4,
                    '2/3': 4.8,
                    '3/4': 5.4,
                    '4/5': 5.9,
                    '5/6': 5.1,
                    '8/9': 7.1,
                    '9/10': 7.2
                });
                break;
            case ModulationScheme.APSK_8:
            case ModulationScheme.PSK_8:
                limit = selectByFEC(channel.signal_info.fec, {
                    '3/5': 6.1,
                    '2/3': 7.0,
                    '3/4': 8.2,
                    '5/6': 9.8,
                    '8/9': 12.1,
                    '9/10': 12.4
                });
                break;
            case ModulationScheme.APSK_16:
                limit = selectByFEC(channel.signal_info.fec, {
                    '2/3': 14.6,
                    '3/4': 14.8,
                    '4/5': 15.3,
                    '5/6': 15.7,
                    '8/9': 16.7,
                    '9/10': 16.9
                });
                break;
            case ModulationScheme.APSK_32:
                limit = selectByFEC(channel.signal_info.fec, {
                    '3/4': 16.4,
                    '4/5': 17.1,
                    '5/6': 17.2,
                    '8/9': 18.6,
                    '9/10': 19.0
                });
                break;
            }
        }

        if (limit === undefined) return undefined;

        if (value < limit) return Quality.Danger;
        if (value < limit + 3) return Quality.Warning;

        return Quality.Good;
    }
}
