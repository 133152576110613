import { V1_API_URL } from '.';
import { ClientMessage, ClientMessageMap, ServerMessage, ServerMessageMap } from 'varos-connect-shared-ts/v1/messages';
import type { WebSocketChannel } from 'varos-connect-shared-ts';
import { DEFAULT_ENABLED_WEBSOCKET_CHANNELS } from 'varos-connect-shared-ts/const';
import { TypedEventTarget } from 'typescript-event-target';
import { TokenService } from './TokenService';

type ServerMessageEventMap = {
    [Key in keyof ServerMessageMap as `message/${Key}`]: CustomEvent<{ payload: ServerMessageMap[Key] }>;
};

type BinaryMessageEventMap = {
    [Type in 'C'|'S'|'I' as `binary_message/${Type}`]: CustomEvent<{ message: ArrayBuffer }>;
};

export type SocketServiceEventMap = ServerMessageEventMap & BinaryMessageEventMap & {
    open: Event,
    close: CloseEvent,
    error: Event,
    message: CustomEvent<{ message: ServerMessage }>,
    binary_message: CustomEvent<{ message: ArrayBuffer }>,
};

class SocketService extends TypedEventTarget<SocketServiceEventMap> {
    private socket: WebSocket;
    private authenticationToken: string|null = null;
    private url: string|URL;
    private messageQueue: Array<string| ArrayBuffer> = [];
    private subscribers = Object.fromEntries(Object.entries(DEFAULT_ENABLED_WEBSOCKET_CHANNELS).map(([x, bool]) => [x, bool ? 1 : 0])) as { [key in WebSocketChannel]: number };

    private static _instance: SocketService|null = null;

    public static get instance (): SocketService {
        if (SocketService._instance === null) {
            const url = new URL('communication_socket', V1_API_URL);
            url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';

            SocketService._instance = new SocketService(url);
        }

        return SocketService._instance;
    }

    private constructor (url: string|URL) {
        super();

        this.url = url;
        this.socket = this.setupSocket();

        TokenService.instance.addEventListener('newtoken', () => this.processQueue());
    }

    private setupSocket (): WebSocket {
        const socket = new WebSocket(this.url, 'kws-v1');
        socket.binaryType = 'arraybuffer';

        socket.addEventListener('open', () => {
            for (const [type, num] of Object.entries(this.subscribers)) {
                this.sendStartStopMessages(type as WebSocketChannel, 0, num);
            }
            this.processQueue();
            this.dispatchTypedEvent('open', new Event('open'));
        });
        socket.addEventListener('close', e => {
            this.authenticationToken = null;
            this.attemptReconnect();
            this.dispatchTypedEvent('close', new CloseEvent('close', e));
        });
        socket.addEventListener('error', e => {
            this.dispatchTypedEvent('error', new Event(e.type, e));
        });
        socket.addEventListener('message', (e: MessageEvent<string|ArrayBuffer>) => {
            try {
                if (e.data instanceof ArrayBuffer) {
                    this.onBinaryMessage(e.data);
                } else {
                    this.onMessage(e.data);
                }
            } catch (err) {
                console.warn('Received faulty message from server:', err);
            }
        });

        return socket;
    }

    public send <K extends keyof ClientMessageMap> (
        type: K,
        payload: ClientMessageMap[K] extends never ? undefined : ClientMessageMap[K]
    ) {
        if (type === 'subscribe' || type === 'unsubscribe') {
            console.warn(`Sending "${type}" messages manually may cause issues. Please use the subscribe/unsubscribe methods of SocketService instead.`);
        }

        const message: [K, ClientMessageMap[K]?] = [type];
        if (payload !== undefined) message.push(payload);
        const json = JSON.stringify(message);
        this.enqueueMessage(json);
    }

    public subscribe (channel: WebSocketChannel) {
        const before = this.subscribers[channel];
        this.subscribers[channel]++;
        const after = this.subscribers[channel];

        this.sendStartStopMessages(channel, before, after);
    }

    public unsubscribe (channel: WebSocketChannel) {
        const before = this.subscribers[channel];
        this.subscribers[channel] = Math.max(this.subscribers[channel] - 1, 0);
        const after = this.subscribers[channel];

        this.sendStartStopMessages(channel, before, after);
    }

    private sendStartStopMessages (channel: WebSocketChannel, before: number, after: number) {
        if (before === after) return;
        if (before > 0 && after > 0) return;

        if (before === 0) {
            // from 0 to >0
            this.enqueueMessage(JSON.stringify(['subscribe', { channel }]));
        } else {
            // from >0 to 0
            this.enqueueMessage(JSON.stringify(['unsubscribe', { channel }]));
        }
    }

    private enqueueMessage (msg: string | ArrayBuffer) {
        this.messageQueue.push(msg);

        this.processQueue();
    }

    private processQueue () {
        if (this.socket.readyState !== this.socket.OPEN) return;
        if (this.authenticationToken === null) {
            if (TokenService.instance.token === undefined) return;

            const msg: ClientMessage = [
                'authenticate',
                { access_token: TokenService.instance.token }
            ];
            this.authenticationToken = TokenService.instance.token;

            this.messageQueue.unshift(JSON.stringify(msg));
        }

        while (true) {
            const msg = this.messageQueue.shift();
            if (msg === undefined) return;

            this.socket.send(msg);
        }
    }

    private onBinaryMessage (rawMessage: ArrayBuffer) {
        if (rawMessage.byteLength === 0) {
            throw new Error('Binary message has zero length');
        }

        const char = String.fromCharCode(new Uint8Array(rawMessage)[0]);

        switch (char) {
        case 'C':
        case 'S':
        case 'I':
            this.dispatchTypedEvent(`binary_message/${char}`, new CustomEvent(
                `binary_message/${char}`,
                { detail: { message: rawMessage } }
            ));
            break;
        default:
            throw new Error('Unknown binary message type');
        }

        this.dispatchTypedEvent('binary_message', new CustomEvent(
            'binary_message',
            { detail: { message: rawMessage } }
        ));
    }

    private onMessage<T extends 'error'> (rawMessage: string) {
        // TODO:
        // In a perfect world T should extends keyof ServerMessageMap, but that would
        // mean that T could be a union type (i.e. 'battery'|'error') and that breaks
        // our Template Literal Types (`message/${T}`). I could not figure out how to
        // specify that only one key of the union is acceptable.

        const message = SocketService.validateJSONMessage(rawMessage);

        const [type, payload] = message as [T, ServerMessageMap[T]];
        this.dispatchTypedEvent(
            `message/${type}`,
            new CustomEvent(
                `message/${type}`,
                { detail: { payload } }
            )
        );
        this.dispatchTypedEvent('message', new CustomEvent(
            'message',
            { detail: { message } }
        ));

        if (type === 'error') {
            if (payload.id === 'unauthorized') {
                this.authenticationToken = null;
                TokenService.instance.fetchToken().catch(() => { /* Intentionally empty */ });

                const msg = typeof payload.cause === 'string' ? payload.cause : new Uint8Array(payload.cause).buffer;

                this.enqueueMessage(msg);
            } else if (payload.id === 'invalid_token') {
                if (payload.id === this.authenticationToken) {
                    this.authenticationToken = null;
                }
            }
        }
    }

    private static validateJSONMessage (rawMessage: string): ServerMessage {
        let json: unknown;
        try {
            json = JSON.parse(rawMessage);
        } catch {
            throw new Error('Message is invalid JSON.');
        }

        if (!Array.isArray(json)) throw new Error('Message is not an array.');
        if (json.length === 0) throw new Error('Array must have a length of at least 1.');
        if (typeof json[0] !== 'string') {
            throw new Error('First item of message must be a string.');
        }

        return json as ServerMessage;
    }

    private attemptReconnect () {
        this.socket = this.setupSocket();
    }
}

export default SocketService;
