import type { OscillatorSettings } from '@/services/types';
import { IFLayer } from 'varos-connect-shared-ts';

export default class ConversionHelper {
    /**
     * @param intermediateFreq Intermediate frequency (in kHz)
     * @param oscillatorSettings Oscillator settings
     * @param layer Intermediate frequency layer
     * @returns Radio Frequency (in kHz)
     */
    public static intermediateFreqToRadioFreq (intermediateFreq: number, oscillatorSettings: OscillatorSettings, layer: IFLayer): number {
        const { kuLow, kuHigh, kuWideband } = oscillatorSettings;

        let loFrequency: number;

        switch (layer) {
        case IFLayer.Vertical:
        case IFLayer.Horizontal:
            loFrequency = kuWideband;
            break;
        case IFLayer.VerticalHigh:
        case IFLayer.HorizontalHigh:
            loFrequency = kuHigh;
            break;
        case IFLayer.Off:
        case IFLayer.VerticalLow:
        case IFLayer.HorizontalLow:
            loFrequency = kuLow;
            break;
        }

        return intermediateFreq + loFrequency * 1000000;
    }

    /**
     * @param radioFreq Radio frequency (in kHz)
     * @param oscillatorSettings Oscillator settings
     * @param layer Intermediate frequency layer
     * @returns Intermediate Frequency (in kHz)
     */
    public static radioFreqToIntermediateFreq (radioFreq: number, oscillatorSettings: OscillatorSettings, layer: IFLayer): number {
        const { kuLow, kuHigh, kuWideband } = oscillatorSettings;

        let loFrequency: number;

        if (layer === IFLayer.Horizontal || layer === IFLayer.Vertical) {
            loFrequency = kuWideband;
        } else if (layer === IFLayer.VerticalLow || layer === IFLayer.HorizontalLow) {
            loFrequency = kuLow;
        } else {
            loFrequency = kuHigh;
        }

        return this.radioAndLoFreqToIntermediateFreq(radioFreq, loFrequency);
    }

    /**
     * @param radioFreq Radio frequency (in kHz)
     * @param loFrequency Oscillator Frequency (in GHz)
     * @returns Intermediate Frequency (in kHz)
     */
    public static radioAndLoFreqToIntermediateFreq (radioFreq: number, loFrequency: number) {
        return Math.abs(loFrequency * 1000000 - radioFreq);
    }

    private static LEVEL_CONVERSION = 90 + 20 * Math.log10(Math.sqrt(75)); // https://www.rfmentor.com/content/dbm-dbw-dbuv-calculators

    public static dBµVTodBm (val_dBµV: number): number {
        return val_dBµV - ConversionHelper.LEVEL_CONVERSION;
    }

    public static dBmTodBµV (val_dBm: number): number {
        return val_dBm + ConversionHelper.LEVEL_CONVERSION;
    }
}
