
import { defineComponent, type PropType } from 'vue';
import { type AvailableNetwork } from 'varos-connect-shared-ts';

export default defineComponent({
    props: {
        model: {
            type: Object as PropType<Pick<AvailableNetwork, 'ssid'|'mac'> & Partial<Pick<AvailableNetwork, 'signal_strength'|'encrypted'>>>,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        }
    }
});
