import Vue, { watch } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import pinia from './plugins/pinia';
import i18n from './plugins/i18n';
import './plugins/globalComponents';
import { useRouteStore, useHelpStore, useLicensingStore, useSettingsStore } from './stores';

/** @deprecated */
export const eventBus = new Vue();

// Allows conditional visibility by using 'v-visible' in the HTML-template
Vue.directive('visible', (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});

Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    vuetify,
    pinia,
    beforeCreate () {
        const routeStore = useRouteStore();

        router.beforeResolve((to, from, next) => {
            routeStore.switchRoute(to);
            next();
        });

        // update the help content when the route is changed
        const helpStore = useHelpStore();
        router.afterEach(() => {
            helpStore.changeTopic();
        });
    },
    render: h => h(App)
}).$mount('#app');

// fetch unlockable features
useLicensingStore().fetchUnlockableFeatures();

{
    const settingsStore = useSettingsStore();
    watch(() => settingsStore.userSettings.language, (lang) => {
        if (lang === undefined) return;
        i18n.locale = lang;
    });
}
