import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('data-logger')))]),_c(VCardText,[_c('div',{staticStyle:{"display":"flex","gap":".5rem"}},[_c(VSelect,{attrs:{"items":_vm.hourItems,"label":_vm.$t('hours')},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}}),_c(VSelect,{attrs:{"items":_vm.minuteItems,"label":_vm.$t('minutes')},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c(VTextField,{attrs:{"maxlength":"20","label":_vm.$t('name-label')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","gap":".5rem"}},[_c(VIcon,[_vm._v("info")]),_c('span',[_vm._v(_vm._s(_vm.$t('datalog-is-saved-automatically')))])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{directives:[{name:"t",rawName:"v-t",value:('cancel'),expression:"'cancel'"}],attrs:{"text":"","color":"primary"},on:{"click":_vm.close}}),_c(VBtn,{directives:[{name:"t",rawName:"v-t",value:('start'),expression:"'start'"}],attrs:{"text":"","color":"primary"},on:{"click":_vm.start}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }