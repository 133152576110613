export default class LayoutHelper {
    // use singleton pattern
    private static instance?: LayoutHelper;

    public static getInstance (): LayoutHelper {
        if (!this.instance) {
            this.instance = new LayoutHelper();
        }
        return this.instance;
    }

    /**
     * Returns true when the window width is <= 500px
     * @deprecated
     */
    public isMobile (): boolean {
        return window.innerWidth <= 500;
    }

    /**
     * Returns a string array which contains the device platform (device-ios or device-android)
     * @deprecated
     */
    public getPlatformClasses (): string[] {
        const classes: string[] = [];
        if (this.isIOS()) {
            classes.push('device-ios');
        }
        if (navigator.userAgent.match(/android/i)) {
            classes.push('device-android');
        }

        return classes;
    }

    /** @deprecated */
    public isIPhone (): boolean {
        return this.isMobile() && this.isIOS();
    }

    /** @deprecated */
    private isIOS () {
        return /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }
}
