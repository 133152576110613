export var DiSEqCVersion;
(function (DiSEqCVersion) {
    DiSEqCVersion["Off"] = "off";
    DiSEqCVersion["DiSEqC_v1_0"] = "v1_0";
    DiSEqCVersion["DiSEqC_v1_1"] = "v1_1";
    DiSEqCVersion["DiSEqC_v1_2"] = "v1_2";
    DiSEqCVersion["DiSEqC_v2_0"] = "v2_0";
    DiSEqCVersion["JESS"] = "jess";
    DiSEqCVersion["UNICABLE"] = "unicable";
})(DiSEqCVersion || (DiSEqCVersion = {}));
export var IFLayer;
(function (IFLayer) {
    /** Off (= 0V) */
    IFLayer["Off"] = "off";
    /** Vertical Low (= 14V) */
    IFLayer["VerticalLow"] = "v_lo";
    /** Horizontal Low (= 18V) */
    IFLayer["HorizontalLow"] = "h_lo";
    /** Vertical High (= 14V / 22kHz) */
    IFLayer["VerticalHigh"] = "v_hi";
    /** Horizontal High (= 18V / 22kHz) */
    IFLayer["HorizontalHigh"] = "h_hi";
    /** Vertical (wide band); not available for JESS */
    IFLayer["Vertical"] = "v";
    /** Horizontal (wide band); not available for JESS */
    IFLayer["Horizontal"] = "h";
})(IFLayer || (IFLayer = {}));
