import { getUnlockableFeatures, unlockFeature } from '@/services';
import { ResponseError } from '@/services/ResponseError';
import { UnlockableFeature } from 'varos-connect-shared-ts';
import { defineStore } from 'pinia';

export const useLicensingStore = defineStore('licensing', {
    state: () => ({
        unlockedFeatures: [] as UnlockableFeature[],
        unlockableFeatures: [] as UnlockableFeature[]
    }),
    actions: {
        async unlock (key: string): Promise<string> {
            try {
                const id = await unlockFeature(key);

                this.unlockedFeatures.push(id);
                this.unlockableFeatures = this.unlockableFeatures.filter(x => x !== id);

                return id;
            } catch (err) {
                if (err instanceof ResponseError && err.status === 423) {
                    throw new Error('Invalid license key');
                }

                throw err;
            }
        },
        async fetchUnlockableFeatures () {
            const features = await getUnlockableFeatures();

            for (const { id, unlocked } of features) {
                if (unlocked) {
                    this.unlockedFeatures.push(id);
                } else {
                    this.unlockableFeatures.push(id);
                }
            }
        }
    },
    getters: {
        isFeatureUnlocked (state) {
            return (id: UnlockableFeature): boolean => state.unlockedFeatures.includes(id);
        }
    }
});
