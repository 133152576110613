
import { computed, defineComponent, ref } from 'vue';

const MINUTES: number[] = [];
for (let index = 0; index <= 59; index++) {
    MINUTES.push(index);
}

export default defineComponent({
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    setup (props, { emit }) {
        const shown = computed<boolean>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const hourItems = ref<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);
        const minuteItems = ref<number[]>(MINUTES);

        const hours = ref(0);
        const minutes = ref(1);
        const name = ref('');

        function close () {
            shown.value = false;
        }

        function start () {
            if (hours.value === 0 && minutes.value === 0) return;

            emit('submit', {
                name: name.value === '' ? undefined : name.value,
                hours: hours.value,
                minutes: minutes.value
            });
        }

        return {
            shown,

            hourItems,
            minuteItems,

            close,
            start,

            hours,
            minutes,
            name
        };
    }
});
