import { ApiErrors } from 'varos-connect-shared-ts';

export class ResponseError<T extends keyof ApiErrors = keyof ApiErrors> extends Error {
    public readonly name = 'ResponseError';
    public readonly response: Response;
    public readonly errorCode: T|null;
    public readonly errorDetails: Promise<ApiErrors[T]|null>;
    public readonly requestMethod: string;

    constructor (requestMethod: string, response: Response) {
        super(ResponseError.generateMessage(requestMethod, response));

        this.response = response;
        this.requestMethod = requestMethod;
        this.errorCode = response.headers.get('KWS-Error') as T|null;
        this.errorDetails = this.errorCode === null ? Promise.resolve(null) : response.json().catch(() => undefined) as Promise<ApiErrors[T]>;
    }

    public get status (): number {
        return this.response.status;
    }

    private static generateMessage (requestMethod: string, response: Response) {
        const status = `${response.status} - ${response.statusText}`;
        const errorCode = response.headers.get('KWS-Error');

        const errorMsg = errorCode ? `${errorCode} (${status})` : status;

        return `${requestMethod} ${response.url}: ${errorMsg}`;
    }
}
