var ModulationScheme;
(function (ModulationScheme) {
    ModulationScheme["QPSK"] = "qpsk";
    ModulationScheme["PSK_8"] = "psk_8";
    ModulationScheme["APSK_8"] = "apsk_8";
    ModulationScheme["APSK_16"] = "apsk_16";
    ModulationScheme["APSK_32"] = "apsk_32";
    ModulationScheme["QAM_16"] = "qam_16";
    ModulationScheme["QAM_32"] = "qam_32";
    ModulationScheme["QAM_64"] = "qam_64";
    ModulationScheme["QAM_128"] = "qam_128";
    ModulationScheme["QAM_256"] = "qam_256";
    ModulationScheme["QAM_512"] = "qam_512";
    ModulationScheme["QAM_1024"] = "qam_1024";
    ModulationScheme["QAM_2048"] = "qam_2048";
    ModulationScheme["QAM_4096"] = "qam_4096";
    ModulationScheme["OFDM"] = "ofdm";
    ModulationScheme["COFDM"] = "cofdm";
    ModulationScheme["DQPSK_PI_4_shift"] = "dqpsk_pi_4";
})(ModulationScheme || (ModulationScheme = {}));
const MODULATION_SCHEMES = [
    undefined,
    ModulationScheme.QPSK,
    ModulationScheme.PSK_8,
    ModulationScheme.APSK_8,
    ModulationScheme.APSK_16,
    ModulationScheme.APSK_32,
    ModulationScheme.QAM_16,
    ModulationScheme.QAM_32,
    ModulationScheme.QAM_64,
    ModulationScheme.QAM_128,
    ModulationScheme.QAM_256,
    ModulationScheme.QAM_512,
    ModulationScheme.QAM_1024,
    ModulationScheme.QAM_2048,
    ModulationScheme.QAM_4096,
    ModulationScheme.OFDM,
    ModulationScheme.COFDM,
    ModulationScheme.DQPSK_PI_4_shift,
];
export function modSchemeFromBinary(num) {
    const val = MODULATION_SCHEMES[num];
    if (val === undefined)
        throw new Error('Binary mod scheme out of range');
    return val;
}
export function modSchemeToBinary(scheme) {
    return MODULATION_SCHEMES.indexOf(scheme);
}
export default ModulationScheme;
