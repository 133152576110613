import { defineStore } from 'pinia';

/** Props of Vuetify's snackbar component (except value ofc) */
interface SnackbarOptions {
    /** Applies **position: absolute** to the component. */
    absolute: boolean;

    /**
     * Respects boundaries of—and will not overlap with—other `app` components
     * like `v-app-bar`, `v-navigation-drawer`, and `v-footer`.
     */
    app: boolean;

    /** Aligns the component towards the bottom. */
    bottom: boolean;

    /** Positions the snackbar in the center of the screen, (x and y axis). */
    centered: boolean;

    /**
     * Applies specified color to the control - it can be the name of material
     * color (for example success or purple) or css color (`#033` or
     * `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the
     * [colors page](https://vuetifyjs.com/styles/colors#material-colors).
     */
    color: string;

    /** Apply a custom class to the snackbar content */
    'content-class': string;

    /**
     * Applies the dark theme variant to the component. You can find more
     * information on the Material Design documentation for
     * [dark themes](https://material.io/design/color/dark-theme.html).
     */
    dark: boolean;

    /**
     * Designates an elevation applied to the component between 0 and 24.
     * You can find more information on the
     * [elevation page](https://vuetifyjs.com/styles/elevation).
     */
    elevation: number|string;

    /** Sets the height for the component. */
    height: number|string;

    /** Aligns the component towards the left. */
    left: boolean;

    /** Applies the light theme variant to the component. */
    light: boolean;

    /** Sets the maximum height for the component. */
    'max-height': number|string;

    /** Sets the maximum width for the component. */
    'max-width': number|string;

    /** Sets the minimum height for the component. */
    'min-height': number|string;

    /** Sets the minimum width for the component. */
    'min-width': number|string;

    /** Gives the snackbar a larger minimum height. */
    'multi-line': boolean;

    /** Removes elevation (box-shadow) and adds a thin border. */
    outlined: boolean;

    /** Aligns the component towards the right. */
    right: boolean;

    /**
     * Designates the **border-radius** applied to the component. You can find
     * more information on the
     * [Border Radius page](https://vuetifyjs.com/styles/border-radius).
     */
    rounded: boolean|string;

    /** Applies a large border radius on the top left and bottom right of the card */
    shaped: boolean;

    /** Specify a custom tag used on the root element. */
    tag: string;

    /** Applies the defined **color** to text and a low opacity background of the same. */
    text: boolean;

    /** Removes the component’s border-radius. */
    tile: boolean;

    /**
     * Time (in milliseconds) to wait until snackbar is automatically hidden. Use
     * `-1` to keep open indefinitely. It is recommended for this number to be
     * between 4000 and 10000. Changes to this property will reset the timeout.
     */
    timeout: number|string;

    /** Aligns the content towards the top. */
    top: boolean;

    /**
     * Sets the component transition. Can be one of the
     * [built in transitions](https://vuetifyjs.com/styles/transitions)
     * or one your own.
     */
    transition: boolean|string;

    /** Stacks snackbar content on top of the actions (button). */
    vertical: boolean;

    /** Sets the width for the component. */
    width: number|string;
}

/** Props of Vuetify's button component */
interface ButtonOptions {
    /** Applies **position: absolute** to the component. */
    absolute: boolean

    /**
     * Configure the active CSS class applied when the link is active. You can
     * find more information about the
     * [**active-class** prop](https://router.vuejs.org/api/#active-class) on the
     * vue-router documentation.
     */
    'active-class': string;

    /**
     * Setting **append** prop always appends the relative path to the current
     * path. You can find more information about the
     * [**append** prop](https://router.vuejs.org/api/#append) on the vue-router
     * documentation.
     */
    append: boolean;

    /** Expands the button to 100% of available space. */
    block: boolean;

    /** Aligns the component towards the bottom. */
    bottom: boolean;

    /**
     * Applies specified color to the control - it can be the name of material
     * color (for example success or purple) or css color (`#033` or
     * `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the
     * [colors page](https://vuetifyjs.com/styles/colors#material-colors)
     */
    color: string;

    /**
     * Applies the dark theme variant to the component. You can find more
     * information on the Material Design documentation for
     * [dark themes](https://material.io/design/color/dark-theme.html).
     */
    dark: boolean;

    /** Removes the button box shadow. */
    depressed: boolean;

    /** Removes the ability to click or target the component. */
    disabled: boolean;

    /**
     * Designates an elevation applied to the component between 0 and 24. You
     * can find more information on the
     * [elevation page](https://vuetifyjs.com/styles/elevation).
     */
    elevation: number | string;

    /**
     * Exactly match the link. Without this, ‘/’ will match every route. You
     * can find more information about the
     * [**exact** prop](https://router.vuejs.org/api/#exact) on the vue-router
     * documentation.
     */
    exact: boolean;

    /**
     * Configure the active CSS class applied when the link is active with exact
     * match. You can find more information about the
     * [**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class)
     * on the vue-router documentation.
     */
    'exact-active-class': string;

    /**
     * Exactly match the link, ignoring the query and the hash sections. You can
     * find more information about the exact-path prop on the vue-router documentation.
     */
    'exact-path': boolean;

    /** Designates the button as a floating-action-button. Button will become round. */
    fab: boolean;

    /** Applies **position: fixed** to the component. */
    fixed: boolean;

    /** Sets the height for the component. */
    height: number | string;

    /** Designates the component as anchor and applies the **href** attribute. */
    href: string | object;

    /**
     * Designates the button as icon. Button will become round and applies
     * the **text** prop.
     */
    icon: boolean;

    /** Controls the button’s active state. */
    'input-value': unknown;

    /** Makes the component large. */
    large: boolean;

    /**
     * Aligns the component towards the left. This should be used with the
     * **absolute** or **fixed** props.
     */
    left: boolean;

    /** Applies the light theme variant to the component. */
    light: boolean;

    /**
     * Designates that the component is a link. This is automatic when using
     * the **href** or **to** prop.
     */
    link: boolean;

    /** Adds a loading icon animation. */
    loading: boolean;

    /** Sets the maximum height for the component. */
    'max-height': number | string;

    /** Sets the maximum width for the component. */
    'max-width': number | string;

    /** Sets the minimum height for the component. */
    'min-height': number | string;

    /** Sets the minimum width for the component. */
    'min-width': number | string;

    /**
     * Specifies the link is a `nuxt-link`. For use with the
     * [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/).
     */
    nuxt: boolean;

    /** Makes the background transparent and applies a thin border. */
    outlined: boolean;

    /** Removes the default background change applied when hovering over the button. */
    plain: boolean;

    /**
     * Setting **replace** prop will call `router.replace()` instead of router.push()
     * when clicked, so the navigation will not leave a history record. You can find
     * more information about the
     * [**replace** prop](https://router.vuejs.org/api/#replace) on the vue-router
     * documentation.
     */
    replace: boolean;

    /** Don’t blur on click. */
    'retain-focus-on-click': boolean;

    /**
     * Aligns the component towards the right. This should be used with the
     * **absolute** or **fixed** props.
     */
    right: boolean;

    /** Applies the [v-ripple](https://vuetifyjs.com/directives/ripple) directive. */
    ripple: boolean | object;

    /** Applies a large border radius on the button. */
    rounded: boolean;

    /** Applies a large border radius on the top left and bottom right of the card. */
    shaped: boolean;

    /** Makes the component small. */
    small: boolean;

    /** Specify a custom tag used on the root element. */
    tag: string;

    /**
     * Designates the target attribute. This should only be applied when
     * using the **href** prop.
     */
    target: string;

    /**
     * Makes the background transparent. When using the **color** prop, the color
     * will be applied to the button text instead of the background.
     */
    text: boolean;

    /** Removes the component’s **border-radius**. */
    tile: boolean;

    /**
     * Denotes the target route of the link. You can find more information about the
     * [**to** prop](https://router.vuejs.org/api/#to) on the vue-router documentation.
     */
    to: string | object;

    /** Aligns the content towards the top. */
    top: boolean;

    /** Set the button’s **type** attribute. */
    type: string;

    /** Controls whether the component is visible or hidden. */
    value: unknown;

    /** Sets the width for the component. */
    width: number | string;

    /** Makes the component extra large. */
    'x-large': boolean;

    /** Makes the component extra small. */
    'x-small': boolean;

}

export interface SnackbarShowOptions {
    text: string;
    snackbar: Partial<SnackbarOptions>;
    button: Partial<ButtonOptions>;
}

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        options: null as null|SnackbarShowOptions
    }),
    actions: {
        /**
         * Show snackbar
         * @param text Text to show
         * @param snackbarOptions Snackbar options. Corresponds to (and will be passed as) props of Vuetify's snackbar component.
         * @param buttonOptions Button options. Corresponds to (and will be passed as) props of Vuetify's button component.
         */
        show (text: string, snackbarOptions: Partial<SnackbarOptions> = {}, buttonOptions: Partial<ButtonOptions> = {}) {
            if (buttonOptions.text === undefined) buttonOptions.text = true;
            if (!snackbarOptions.top && !snackbarOptions.bottom && !snackbarOptions.right && !snackbarOptions.left) {
                snackbarOptions.bottom = true;
                snackbarOptions.right = true;
            }

            this.options = { text, snackbar: snackbarOptions, button: buttonOptions };
        }
    }
});
