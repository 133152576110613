import type { MeasurementOptions } from '@/services/types';
import { Dictionary } from 'vue-router/types/router';

// const query: { [key: string]: string|null } = {};
// for (const key in options) {
//     if (Object.prototype.hasOwnProperty.call(options, key)) {
//         const element = options[key as keyof ExpertMeasurementOptions];
//         if (element !== undefined) query[key] = element.toString();
//         if (typeof element === 'boolean') query[key] = null;
//     }
// }
// if (freqDisplayName) query.freqDisplayName = freqDisplayName;

type Query = Dictionary<null | string | (string | null)[]>;

type MeasurementOptsQuery = Omit<MeasurementOptions, 'frequency'> & { planId?: string };

interface QueryConfig<K extends keyof MeasurementOptsQuery> {
    fromQuery: (query: Query, key: string) => MeasurementOptsQuery[K]|undefined,
    queryName?: string
}

export default class QueryHelper {
    private static MEASUREMENT_OPTIONS_CONFIG: { [K in keyof Required<MeasurementOptsQuery>]: QueryConfig<K> } = {
        afc: {
            fromQuery: QueryHelper.getQueryBool
        },
        planId: {
            fromQuery: QueryHelper.getQuery,
            queryName: 'plan'
        },
        display_name: {
            fromQuery: QueryHelper.getQuery,
            queryName: 'name'
        },
        mod_standard: {
            fromQuery: QueryHelper.getQuery,
            queryName: 'std'
        },
        mod_scheme: {
            fromQuery: QueryHelper.getQuery,
            queryName: 'scheme'
        },
        bandwidth: {
            fromQuery: QueryHelper.getQueryNumber,
            queryName: 'bw'
        },
        symbol_rate: {
            fromQuery: QueryHelper.getQueryNumber,
            queryName: 'sr'
        },
        plp_id: {
            fromQuery: QueryHelper.getQueryNumber,
            queryName: 'plp'
        },
        with_docsis_modem: {
            fromQuery: QueryHelper.getQueryBool,
            queryName: 'allow_modem'
        }
    };

    public static measurementOptionsToQuery (options: MeasurementOptsQuery): Query {
        const query: Query = {};

        for (const k in QueryHelper.MEASUREMENT_OPTIONS_CONFIG) {
            const attribute = k as keyof typeof QueryHelper.MEASUREMENT_OPTIONS_CONFIG;
            const value = options[attribute];
            if (value === undefined) continue;

            const config = QueryHelper.MEASUREMENT_OPTIONS_CONFIG[attribute];
            const queryName = config.queryName ?? attribute;

            switch (typeof value) {
            case 'boolean':
            case 'number':
            case 'string':
                query[queryName] = value.toString();
                break;
            default:
                throw new Error('Can only serialize booleans, numbers and string to query.');
            }
        }

        return query;
    }

    public static measurementOptionsFromQuery (query: Query): MeasurementOptsQuery {
        const options: MeasurementOptsQuery = {};

        for (const k in QueryHelper.MEASUREMENT_OPTIONS_CONFIG) {
            (function<K extends keyof typeof QueryHelper.MEASUREMENT_OPTIONS_CONFIG> () {
                const attribute = k as K;
                const config = QueryHelper.MEASUREMENT_OPTIONS_CONFIG[attribute];
                const queryName = config.queryName ?? attribute;
                options[attribute] = config.fromQuery(query, queryName) as MeasurementOptsQuery[K];
            })();
        }

        return options;
    }

    public static getQuery<T> (query: Query, key: string): T|undefined {
        const item = query[key];
        const [firstOccurrence] = Array.isArray(item) ? item : [item];
        return firstOccurrence as T|null ?? undefined;
    }

    public static getQueryNumber (query: Query, key: string): number|undefined {
        const str = QueryHelper.getQuery<string>(query, key);
        const num = Number.parseFloat(str ?? '');
        return Number.isNaN(num) ? undefined : num;
    }

    public static getQueryBool (query: Query, key: string): undefined|boolean {
        if (!(key in query)) return undefined;

        const str = QueryHelper.getQuery<string>(query, key);

        return str?.toLowerCase().trim() !== 'false';
    }
}
