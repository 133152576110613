import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.edit || _vm.active)?_c(VSheet,{staticClass:"measure-card",class:{
        'measure-card--edit': _vm.edit,
        'measure-card--inactive': _vm.inactive
    },style:(_vm.style),attrs:{"elevation":_vm.inactive ? 1 : 3,"rounded":""},on:{"click":function($event){_vm.edit ? _vm.$emit('update:active', !_vm.active) : undefined}}},[(_vm.edit && _vm.active)?_c(VIcon,{staticClass:"measure-card__active-icon",attrs:{"color":"success"}},[_vm._v("check_circle")]):_vm._e(),(!_vm.edit && _vm.helpIconTarget.length > 0)?_c('kws-help-icon',{staticClass:"measure-card__help-icon",attrs:{"target":_vm.helpIconTarget}}):_vm._e(),_c('span',{staticClass:"measure-card__label text-overline"},[_vm._t("label",function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.field),expression:"field"}]})]})],2),_c('span',{staticClass:"measure-card__value text-h6 text-sm-h5 text-md-h4 text-lg-h3"},[_vm._v(_vm._s(_vm.formattedValue))]),(_vm.unit.length > 0 && _vm.value !== undefined)?_c('span',{staticClass:"measure-card__unit text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4"},[_vm._v(" "+_vm._s(_vm.unit))]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }