var MeasuredValue;
(function (MeasuredValue) {
    MeasuredValue["Level"] = "level";
    MeasuredValue["MER"] = "mer";
    MeasuredValue["PreBER"] = "pre_ber";
    MeasuredValue["PostBER"] = "post_ber";
    MeasuredValue["PER"] = "per";
    MeasuredValue["NoiseMargin"] = "noise_margin";
    MeasuredValue["PacketErrors"] = "packet_errors";
})(MeasuredValue || (MeasuredValue = {}));
export default MeasuredValue;
