import { socketService, updateLNBSettings as apiUpdateLNBSettings } from '@/services';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { DOCSISModemStatus, LNBSettings, LockedChannel, LockOptions } from 'varos-connect-shared-ts';
import type { ServerMessageMap } from 'varos-connect-shared-ts/v1/messages';

export const useDeviceStore = defineStore('device', () => {
    const lockOptions = ref<LockOptions|null>(null);
    const lockedChannel = ref<LockedChannel|null>(null);
    const lnbSettings = ref<LNBSettings|null>(null);
    const scanning = ref(false);
    const docsisModemStatus = ref(DOCSISModemStatus.Off);
    const docsisNetwork = ref<ServerMessageMap['docsis/network_info']|null>(null);

    const locked = computed<boolean>(() => lockedChannel.value !== null);

    socketService.addEventListener('message/initial_scan_running', () => { scanning.value = true; });
    socketService.addEventListener('message/initial_scan_done', () => { scanning.value = false; });
    socketService.addEventListener('message/locked', e => { lockedChannel.value = e.detail.payload; });
    socketService.addEventListener('message/unlocked', () => { lockedChannel.value = null; });
    socketService.addEventListener('message/lock_options', e => { lockOptions.value = e.detail.payload ?? null; });
    socketService.addEventListener('message/lnb_settings', e => { lnbSettings.value = e.detail.payload; });
    socketService.addEventListener('message/docsis/modem_status', e => { docsisModemStatus.value = e.detail.payload.status; });
    socketService.addEventListener('message/docsis/network_info', e => { docsisNetwork.value = e.detail.payload; });

    socketService.subscribe('initial_scan');
    socketService.subscribe('locked_channel');
    socketService.subscribe('lock_options');
    socketService.subscribe('lnb_settings');
    socketService.subscribe('docsis/modem_status');
    socketService.subscribe('docsis/network');

    function lock (options: LockOptions) {
        // set all properties manually to make sure message
        // does not include any additional properties
        const {
            transmission_method,
            frequency,
            afc,
            with_docsis_modem,
            bandwidth,
            mod_scheme,
            mod_standard,
            plp_id,
            symbol_rate
        } = options;

        socketService.send(
            'lock',
            { transmission_method, frequency, afc, with_docsis_modem, bandwidth, mod_scheme, mod_standard, plp_id, symbol_rate }
        );
    }

    async function updateLNBSettings (settings: LNBSettings) {
        await apiUpdateLNBSettings(settings);
    }

    return {
        lockedChannel,
        lnbSettings,

        locked,
        scanning,

        lock,
        updateLNBSettings,
        docsisModemStatus,
        docsisNetwork
    };
});
