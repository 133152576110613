import router from '../router';
import HelpTopic from '@/services/types/help/HelpTopic';
import TopicSearchService from './TopicSearchService';
import { useHelpStore, useSettingsStore } from '@/stores';

export default class HelpTopicService {
    // Use singleton pattern
    private static instance?: HelpTopicService;

    private helpStore: ReturnType<typeof useHelpStore>;

    public static getInstance (): HelpTopicService {
        if (!this.instance) {
            this.instance = new HelpTopicService();
        }
        return this.instance;
    }

    // Array of all HelpTopics (without their content) based on help-topics.json
    private helpTopicArray: HelpTopic[];

    // Contains the same content as the helpTopicArray but hierarchical structured
    private tableOfContents: HelpTopic[] = new Array<HelpTopic>();

    private constructor () {
        this.helpStore = useHelpStore();

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        this.helpTopicArray = require('../assets/help-topics.json').topics;
    }

    /**
     * Returns the content of a HelpTopic as a string
     * @param {string} fileName
     */
    public async getTopicContent (fileName: string): Promise<string> {
        const language: string = useSettingsStore().getUserSetting('language');

        const headers = new Headers();
        headers.append('Accept', 'application/md');
        headers.append('Content-Type', 'application/md');
        let helpTopicContent = '';
        let response = new Response();
        try {
            response = await fetch(process.env.BASE_URL + 'HelpContent/MarkdownFiles/' + language + '/' + fileName + '.md', {
                headers
            });
        } catch (error) {
            console.error('Topic ' + fileName + ' for language ' + language + ' not found.');
        }

        if (response.ok) {
            helpTopicContent = await response.text();
        } else {
            helpTopicContent = router.app.$t('topic-not-found').toString();
        }

        return helpTopicContent;
    }

    /**
     * Returns the content of the currently selected HelpTopic
     */
    public async getSelectedHelpTopicContent (): Promise<string> {
        if (this.helpStore.topic === null) return Promise.resolve('');

        return await this.getTopicContent(this.helpStore.topic);
    }

    /**
     * Generates an Array of HelpTopics for the table of contents based on an json-object
     */
    public generateTableOfContents (): HelpTopic[] {
        if (this.tableOfContents.length === 0) {
            this.helpTopicArray.forEach(topicLv1 => {
                // find all topics for Lv1 (they don't have a parent)
                if (topicLv1.parent === '') {
                    // add topic to LV1 of the table of contents if it has no parent
                    this.tableOfContents.push(topicLv1);
                    topicLv1.children = new Array<HelpTopic>();
                    // find all topics that have this Lv1-topic as parent
                    this.helpTopicArray.forEach(topicLv2 => {
                        if (topicLv1.fileName === topicLv2.parent) {
                            // add Lv2-topic to the children-array of the Lv1-topic
                            topicLv1.children.push(topicLv2);
                            topicLv2.children = new Array<HelpTopic>();
                            // find all topics that have this Lv2-topic as parent
                            this.helpTopicArray.forEach(topicLv3 => {
                                if (topicLv2.fileName === topicLv3.parent) {
                                    // add Lv3 topic to the children-array of the Lv2-topic
                                    topicLv2.children.push(topicLv3);
                                }
                            });
                        }
                    });
                }
            });
        }

        return this.tableOfContents;
    }

    /**
     * Returns an Array of topicFileNames of all superordinate topics (not just the next higher one)
     * @param topicFileName
     */
    public getSuperordinateTopics (topicFileName: string): string[] {
        let parents = new Array<string>();
        const targetTopic: HelpTopic|undefined = this.helpTopicArray.find(topic => topic.fileName === topicFileName);

        if (targetTopic !== undefined) {
            const parent: string = targetTopic.parent;
            if (parent !== '') {
                parents.push(parent);
                // also add recursively the parent of the parent
                parents = parents.concat(this.getSuperordinateTopics(parent));
            }
        }
        return parents;
    }

    /**
     * Returns an array of all HelpTopics based on help-topics.json
     */
    public getHelpTopicArray (): HelpTopic[] {
        return this.helpTopicArray;
    }

    /**
     * Returns an array of HelpTopics of related topics or just an empty array
     * when there are no related topics for the selected HelpTopic
     */
    public getRelatedTopicsOfSelectedTopic (): HelpTopic[] {
        // array with filenames of all related topics
        let relatedTopicsFileNames: string[] = new Array<string>();
        const relatedTopics: HelpTopic[] = new Array<HelpTopic>();
        const selectedHelpTopicFileName: string|null = this.helpStore.topic;

        const selectedHelpTopic: HelpTopic | undefined =
        this.helpTopicArray.find(topic => topic.fileName === selectedHelpTopicFileName);

        if (selectedHelpTopic !== undefined) {
            relatedTopicsFileNames = selectedHelpTopic.relatedTopics || [];
        }
        relatedTopicsFileNames.forEach(fileName => {
            const topic: HelpTopic = this.findTopic(fileName);
            if (topic) {
                relatedTopics.push(topic);
            }
        });

        return relatedTopics;
    }

    /**
     * Returns a HelpTopic object for a given fileName
     * @param fileName
     */
    private findTopic (fileName: string): HelpTopic {
        return TopicSearchService.getInstance().findTopic(fileName);
    }

    /**
     * Returns an array of all topics for the GetStarted Feature
     */
    public async getGetStartedTopics (): Promise<HelpTopic[]> {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const getStartedTopics: HelpTopic[] = require('../assets/get-started-topics.json').topics;

        for (const topic of getStartedTopics) {
            topic.content = await this.getTopicContent(topic.fileName);
        }

        return getStartedTopics;
    }
}
