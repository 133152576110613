
import { useRules } from '@/composables/useValidationRules';
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    setup (props, { emit }) {
        const model = computed<string>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const rules = useRules(computed(() => ({
            pattern: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/,
            isRequired: props.required
        })));

        return {
            rules,
            model
        };
    }
});
