
import { Component, Vue, Watch } from 'vue-property-decorator';
import HelpTopicService from '@/utils/HelpTopicService';
import HelpTopic from '@/services/types/help/HelpTopic';
import SearchHelper from '@/utils/TopicSearchService';
import { HelpContentContainer, SearchResults, TableOfContents } from '@/components/Help';
import LayoutHelper from '@/utils/LayoutHelper';
import { mapStores } from 'pinia';
import { useHelpStore } from '@/stores';

@Component({
    components: { HelpContentContainer, SearchResults, TableOfContents },
    computed: {
        ...mapStores(useHelpStore)
    }
})
export default class HelpPanel extends Vue {
    private helpStore!: ReturnType<typeof useHelpStore>;

    private tableOfContents: HelpTopic[] = new Array<HelpTopic>();
    private helpContentAsMarkdown = '';
    private relatedTopics: HelpTopic[] = new Array<HelpTopic>();

    private isInputFieldShown = false;
    private isTableOfContentsShown = false;

    private searchInput = '';
    private searchResults: HelpTopic[] = [];

    private loading = false;

    /** Initialize the searchHelper at the beginning because it takes a while
     *  to load all the necessary content. When you don't do this, then the first single charcter search request
     *  won't return any results
     */
    private searchHelper: SearchHelper = SearchHelper.getInstance();

    get mobile (): boolean {
        return LayoutHelper.getInstance().isMobile();
    }

    private created () {
        this.tableOfContents = HelpTopicService.getInstance().generateTableOfContents();
    }

    private get isHelpPanelVisible (): boolean {
        return this.helpStore.visible;
    }

    private close () {
        this.helpStore.toggle();
        // if you close when help is fullscreen
        this.isFullscreen = false;
    }

    private toggleFullsize () {
        this.isFullscreen = !this.isFullscreen;
        this.isTableOfContentsShown = false;
    }

    private get isFullscreen () { return this.helpStore.fullScreen; }
    private set isFullscreen (val: boolean) { this.helpStore.fullScreen = val; }

    /**
     * Method to automatically rerender the component when the selected HelpTopic in the Vuex store changes
     */
    @Watch('helpStore.topic')
    private async watchSelectedHelpTopic () {
        this.helpContentAsMarkdown = await HelpTopicService.getInstance().getSelectedHelpTopicContent();

        this.relatedTopics = HelpTopicService.getInstance().getRelatedTopicsOfSelectedTopic();

        const el = this.$refs.content as Vue|undefined;
        if (this.isHelpPanelVisible && el) {
            // Scroll to top of the entry when topic is changed
            el.$el.scrollTo(0, 0);
        }

        // close table of contents and hide search results
        this.isTableOfContentsShown = false;
        this.searchInput = '';
    }

    private showInputField () {
        this.isInputFieldShown = true;
        this.isTableOfContentsShown = false;
    }

    private toggleTableOfContents () {
        this.isTableOfContentsShown = !this.isTableOfContentsShown;
        this.closeSearch();
    }

    /**
     * Method to automatically search when the input changes
     */
    @Watch('searchInput')
    private async search () {
        this.loading = true;
        this.searchResults = await this.searchHelper.search(this.searchInput);
        this.loading = false;
    }

    private closeSearch () {
        this.isInputFieldShown = false;
        this.searchInput = '';
    }
}
