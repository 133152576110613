import { MeasuredValue, ModulationScheme, ModulationStandard, TransmissionMethod } from './mod.js';
export const MEASURED_VALUE_UNITS = {
    [MeasuredValue.Level]: 'dBμV',
    [MeasuredValue.MER]: 'dB',
    [MeasuredValue.PreBER]: undefined,
    [MeasuredValue.PostBER]: undefined,
    [MeasuredValue.NoiseMargin]: 'dB',
    [MeasuredValue.PacketErrors]: undefined,
    [MeasuredValue.PER]: undefined,
};
export const TRANSMISSION_METHOD_MEASURED_VALUES = {
    [TransmissionMethod.Cable]: [
        MeasuredValue.Level,
        MeasuredValue.MER,
        MeasuredValue.NoiseMargin,
        MeasuredValue.PreBER,
        MeasuredValue.PacketErrors,
        MeasuredValue.PER,
    ],
    [TransmissionMethod.Antenna]: [
        MeasuredValue.Level,
        MeasuredValue.MER,
        MeasuredValue.NoiseMargin,
        MeasuredValue.PreBER,
        MeasuredValue.PostBER,
        MeasuredValue.PacketErrors,
        MeasuredValue.PER,
    ],
    [TransmissionMethod.Satellite]: [
        MeasuredValue.Level,
        MeasuredValue.MER,
        MeasuredValue.NoiseMargin,
        MeasuredValue.PreBER,
        MeasuredValue.PostBER,
        MeasuredValue.PER,
    ],
    [TransmissionMethod.Radio]: [
        MeasuredValue.Level,
        MeasuredValue.MER,
        MeasuredValue.NoiseMargin,
        MeasuredValue.PreBER,
    ],
};
export const TRANSMISSION_METHOD_MODULATION_STANDARDS = {
    [TransmissionMethod.Cable]: [ModulationStandard.DVB_C, ModulationStandard.DOCSIS],
    [TransmissionMethod.Antenna]: [ModulationStandard.DVB_T, ModulationStandard.DVB_T2],
    [TransmissionMethod.Satellite]: [ModulationStandard.DVB_S, ModulationStandard.DVB_S2, ModulationStandard.DVB_S2X],
    [TransmissionMethod.Radio]: [ModulationStandard.DAB],
};
export const MODULATION_STANDARD_MODULATION_SCHEMES = {
    [ModulationStandard.DVB_C]: [
        ModulationScheme.QAM_16,
        ModulationScheme.QAM_32,
        ModulationScheme.QAM_64,
        ModulationScheme.QAM_128,
        ModulationScheme.QAM_256,
    ],
    [ModulationStandard.DOCSIS]: [
        ModulationScheme.QAM_64,
        ModulationScheme.QAM_256,
        ModulationScheme.OFDM,
    ],
    [ModulationStandard.DVB_T]: [ModulationScheme.COFDM],
    [ModulationStandard.DVB_T2]: [ModulationScheme.COFDM],
    [ModulationStandard.DVB_S]: [ModulationScheme.QPSK],
    [ModulationStandard.DVB_S2]: [
        ModulationScheme.QPSK,
        ModulationScheme.PSK_8,
        ModulationScheme.APSK_8,
        ModulationScheme.APSK_16,
        ModulationScheme.APSK_32,
    ],
    [ModulationStandard.DVB_S2X]: [
        ModulationScheme.QPSK,
        ModulationScheme.PSK_8,
        ModulationScheme.APSK_8,
        ModulationScheme.APSK_16,
        ModulationScheme.APSK_32,
    ],
    [ModulationStandard.DAB]: [ModulationScheme.COFDM],
};
export const DEFAULT_ENABLED_WEBSOCKET_CHANNELS = {
    battery: true,
    blind_scan_channel: false,
    blind_scan_status: true,
    'docsis/selected_measured_channel': true,
    datalog_status: true,
    'docsis/modem_status': true,
    lnb_settings: true,
    lock_options: true,
    locked_channel: true,
    remote_supply_voltage: true,
    measurement: false,
    spectrum: false,
    constellation: false,
    impulse_response: false,
    'docsis/network': false,
    'docsis/mer_over_freq': false,
    'docsis/freq_response': false,
    remote_supply_current: false,
    initial_scan: true,
};
