
import { type PropType, defineComponent, computed, ref, watch } from 'vue';
import { type MeasuredValue, type DatalogEntry } from 'varos-connect-shared-ts';
import { cancelDatalog, newDatalog, socketService } from '@/services';
import DataloggerParamsDialogVue from './ParamsDialog.vue';
import DataloggerChartVue from './Chart.vue';
import { useSocketSubscription } from '@/composables/socket';
import { useTypedEventListener } from '@/composables/eventlistener';
import { useDeviceStore } from '@/stores';

export default defineComponent({
    components: {
        Chart: DataloggerChartVue,
        ParamsDialog: DataloggerParamsDialogVue
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        valuesToMeasure: {
            type: Array as PropType<MeasuredValue[]>,
            required: true
        }
    },
    setup (props, { emit }) {
        const shown = computed<boolean>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const paramsDialog = ref(false);
        const mainDialog = ref(false);
        const closeDialog = ref(false);

        const finished = ref(false);

        const highlightedPoint = ref(0);
        const period = ref({ min: Number.MAX_VALUE, max: Number.MAX_VALUE });

        const data = ref<DatalogEntry[]>([]);

        const deviceStore = useDeviceStore();

        useSocketSubscription('measurement');
        useTypedEventListener(socketService, 'message/measurement_data', (e) => {
            if (finished.value) return;

            const d = e.detail.payload;

            if (d.time < period.value.min) return;
            if (d.time > period.value.max) {
                finished.value = true;
                return;
            }

            data.value.push({
                locked: deviceStore.locked,
                measurement_data: d
            });
        });

        function clear () {
            data.value = [];
        }

        /** Callback of params dialog submit event. Starts a log */
        async function start ({ name, hours, minutes }: { hours: number, minutes: number, name?: string }) {
            finished.value = false;
            mainDialog.value = true;
            paramsDialog.value = false;
            clear();

            // duration in ms (1 minute = 60 000 ms)
            const duration = (hours * 60 + minutes) * 60000;

            const res = await newDatalog(duration, name);
            // TODO: Handle error for user

            period.value = { min: res.start_date, max: res.start_date + res.duration };
        }

        function restart () {
            paramsDialog.value = true;
        }

        async function cancel () {
            await cancelDatalog();
            // TODO: Handle error for user
            finished.value = true;
        }

        function close () {
            if (finished.value) {
                // just close, if no log is running
                shown.value = false;
            } else {
                // let user confirm, that he actually wants to close
                closeDialog.value = true;
            }
        }

        function forceClose () {
            cancel();
            shown.value = false;
        }

        /**
         * React to v-model changes.
         * If value turns...
         * ...false, we'll stop any running logs, clear and close everything
         * ...true, we'll open the params dialog
         */
        watch(shown, (val: boolean, old: boolean) => {
            if (val === old) return;

            if (val) {
                paramsDialog.value = true;
            } else {
                paramsDialog.value = false;
                closeDialog.value = false;
                mainDialog.value = false;
                cancel();
                clear();
            }
        });

        /** Make sure "shown" is false, if both dialogs are closed */
        watch([paramsDialog, mainDialog], () => {
            if (!paramsDialog.value && !mainDialog.value) {
                shown.value = false;
            }
        });

        return {
            paramsDialog,
            start,
            closeDialog,
            forceClose,
            mainDialog,
            finished,
            cancel,
            restart,
            close,
            data,
            highlightedPoint,
            period
        };
    }
});
