
import { Component, Vue, Prop } from 'vue-property-decorator';
import HelpTopic from '@/services/types/help/HelpTopic';
import { mapStores } from 'pinia';
import { useHelpStore } from '@/stores';

@Component({
    computed: {
        ...mapStores(useHelpStore)
    }
})
export default class SearchResults extends Vue {
    private helpStore!: ReturnType<typeof useHelpStore>;

    @Prop()
    private searchResults!: HelpTopic[];

    @Prop()
    private searchInput!: string;

    @Prop()
    private closeFunction!: () => void;

    @Prop()
    private isFullscreen!: boolean;

    private showSearchInputField = false;

    @Prop()
    private loading = false;

    get results (): HelpTopic[] {
        return this.searchResults;
    }

    private showInputField () {
        this.showSearchInputField = true;
    }

    /**
     * Opens the HelpTopic with topicFileName and closes the search results
     */
    private openTopic (topicFileName: string) {
        this.helpStore.changeTopic(topicFileName);
        this.closeFunction();
    }

    /**
     * Removes the first line of a string and all html-tags incl. their content
     */
    private formatPreview (text: string): string {
        return text.replace(/^.*[\n\r]/, '')
            // limits preview to 200 characters
            .substring(0, 200)
            // removes all html tags
            .replace(/<([^>]+?)([^>]*?)>([\S\s]*?)<\/\1>/ig, '')
            // removes all '# ' (it's the markdown-syntax for headers) plus one or more words
            .replace(/#+ (\w( )?)+/g, '')
            // removes empty lines
            .replace(/\s*\n+/g, '');
    }
}

