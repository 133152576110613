import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"datalogger"},[_c('ParamsDialog',{on:{"submit":_vm.start},model:{value:(_vm.paramsDialog),callback:function ($$v) {_vm.paramsDialog=$$v},expression:"paramsDialog"}}),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.closeDialog),callback:function ($$v) {_vm.closeDialog=$$v},expression:"closeDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('close-datalogger'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('datalog-will-be-cancelled'))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.closeDialog = false;}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":_vm.forceClose}},[_vm._v(_vm._s(_vm.$t('close')))])],1)],1)],1),_c(VDialog,{attrs:{"fullscreen":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.mainDialog),callback:function ($$v) {_vm.mainDialog=$$v},expression:"mainDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","outlined":"","elevation":0,"rounded":false}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('datalogger')))]),_c(VSpacer),_c(VToolbarItems,[(!_vm.finished)?_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.restart}},[_vm._v(_vm._s(_vm.$t('restart')))]),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("close")])],1)],1)],1),_c(VCardText,{staticStyle:{"padding":"0"}},[_c('div',_vm._l((_vm.valuesToMeasure),function(field){return _c('Chart',{key:field,attrs:{"field":field,"data":_vm.data,"highlightedPoint":_vm.highlightedPoint,"period":_vm.period},on:{"update:highlightedPoint":function($event){_vm.highlightedPoint=$event},"update:highlighted-point":function($event){_vm.highlightedPoint=$event}}})}),1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }