
import { useHelpStore } from '@/stores';
import { computed, defineComponent } from 'vue';
import { useUserSetting } from '@/composables/settings';

export default defineComponent({
    props: {
        target: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: '20px'
        }
    },
    setup (props) {
        const style = computed(() => ({ '--font-size': props.size }));

        const showHelpIcon = useUserSetting('show_help_icons');

        function openHelp () {
            const helpStore = useHelpStore();
            helpStore.openTopic(props.target);
        }

        return {
            showHelpIcon,
            openHelp,
            style
        };
    }
});
