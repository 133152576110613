
import { Vue } from 'vue-property-decorator';
import { computed, defineComponent, ref, watch } from 'vue';
import Toolbar from '@/components/Toolbar/Toolbar.vue';
import HelpPanel from '@/components/Help/HelpPanel.vue';
import LayoutHelper from '@/utils/LayoutHelper';
import { useSnackbarStore, SnackbarShowOptions, useHelpStore } from '@/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
    components: { Toolbar, HelpPanel },
    setup () {
        const snackbarShown = ref(false);
        const snackbarOptions = ref<SnackbarShowOptions|null>(null);

        const snackbarStore = useSnackbarStore();
        const { options } = storeToRefs(snackbarStore);
        watch(options, (val) => {
            if (val === null) return;

            snackbarOptions.value = val;

            snackbarShown.value = true;
            options.value = null;
        });

        const helpStore = useHelpStore();

        function hideHelpPanel () {
            if (window.innerWidth > 499 && window.innerWidth < 505) {
                helpStore.visible = false;
            }
        }

        addEventListener('resize', () => {
            if (window.innerWidth < 700) {
                Vue.prototype.$isMobile = true;
            } else {
                Vue.prototype.$isMobile = false;
            }
            hideHelpPanel();
        });

        const helpPanelFullScreen = computed(() => helpStore.fullScreen || (helpStore.visible && LayoutHelper.getInstance().isMobile()));

        const classes = computed(() => {
            const classes: string[] = LayoutHelper.getInstance().getPlatformClasses();
            if (helpStore.visible) classes.push('help--visible');
            if (helpStore.fullScreen) classes.push('help--fullscreen');
            if (LayoutHelper.getInstance().isIPhone()) {
                classes.push('device-iPhone');
            }
            return classes;
        });

        return {
            snackbarShown,
            snackbarOptions,
            helpPanelFullScreen,
            classes
        };
    }
});
