import { isRef, onActivated, onDeactivated, onMounted, onUnmounted, ref, unref, watch } from 'vue';
import { socketService } from '@/services';
import type { WebSocketChannel } from 'varos-connect-shared-ts';
import { MaybeRef } from '@vueuse/core';

export function useSocketSubscription (channel: WebSocketChannel, subscribe: MaybeRef<boolean> = true) {
    const isSubscribed = ref(false);

    function subscribeFn () {
        if (!unref(subscribe)) return;
        if (isSubscribed.value) return;
        socketService.subscribe(channel);
        isSubscribed.value = true;
    }

    function unsubscribeFn () {
        if (!isSubscribed.value) return;
        socketService.unsubscribe(channel);
        isSubscribed.value = false;
    }

    onMounted(subscribeFn);
    onActivated(subscribeFn);
    onDeactivated(unsubscribeFn);
    onUnmounted(unsubscribeFn);

    if (isRef(subscribe)) {
        watch(subscribe, (val) => {
            if (val) {
                subscribeFn();
            } else {
                unsubscribeFn();
            }
        });
    }
}
