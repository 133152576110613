
import { Vue, Prop, Component } from 'vue-property-decorator';
import HelpTopic from '@/services/types/help/HelpTopic';
import { mapStores } from 'pinia';
import { useHelpStore } from '@/stores';

@Component({
    computed: {
        ...mapStores(useHelpStore)
    }
})
export default class RelatedTopics extends Vue {
    private helpStore!: ReturnType<typeof useHelpStore>;

    @Prop()
    private topics?: HelpTopic[];

    private language: string = sessionStorage.getItem('kws-lang') || 'de';

    private goToTopic (fileName: string): void {
        this.helpStore.changeTopic(fileName);
    }
}
