import router from '@/router';
import { defineStore } from 'pinia';

export const useHelpStore = defineStore('help', {
    state: () => ({
        visible: false,
        topic: null as string|null,
        fullScreen: false
    }),
    actions: {
        toggle () {
            this.visible = !this.visible;
        },
        changeTopic (topic?: string) {
            this.topic = topic ?? router.currentRoute.meta?.helpTopic ?? 'TopicNotFound';
        },
        openTopic (topic?: string) {
            this.changeTopic(topic);
            this.visible = true;
        }
    }
});
