
import { defineComponent, defineAsyncComponent, type PropType, ref } from 'vue';
import type { GroupSetting } from '@/services/types';
import AbstractSetting from './AbstractSetting.vue';

export default defineComponent({
    components: {
        // async component to prevent circular components-references
        Setting: defineAsyncComponent(() => import('./Setting.vue')),
        AbstractSetting
    },
    props: {
        model: {
            type: Object as PropType<GroupSetting>,
            required: true
        },
        expanded: {
            type: Boolean,
            default: true
        },
        disablePadding: {
            type: Boolean,
            default: false
        }
    },
    setup (props) {
        const isExpanded = ref(props.expanded);

        return {
            isExpanded
        };
    }
});
