
import MeasurementQualityLimitsHelper, { Quality } from '@/utils/MeasurementQualityLimitsHelper';
import { LockedChannel, MeasuredValue } from 'varos-connect-shared-ts';
import { useDeviceStore } from '@/stores/device';
import { computed, defineComponent, toRefs, type PropType } from 'vue';
import { useUserSetting } from '@/composables/settings';
import { useFormatters } from '@/composables/useFormatters';
import { MEASURED_VALUE_UNITS } from 'varos-connect-shared-ts/const';
import { computedWithControl } from '@vueuse/core';

export default defineComponent({
    props: {
        field: {
            type: String as PropType<MeasuredValue>,
            required: true
        },
        value: {
            type: Number
        },
        edit: {
            type: Boolean,
            default: false
        },
        helpIconTarget: {
            type: String,
            default: ''
        },
        active: {
            type: Boolean,
            default: true
        },
        channel: {
            type: Object as PropType<Pick<LockedChannel, 'mod_scheme'|'mod_standard'|'signal_info'>>
        }
    },
    setup (props) {
        const { lockedChannel } = toRefs(useDeviceStore());

        const inactive = computed(() => props.edit && !props.active);

        const showColoredValues = useUserSetting('show_colored_values');

        const levelUnit = useUserSetting('level_unit');

        const unit = computedWithControl<string, unknown>(
            [() => props.field, levelUnit],
            () => {
                if (props.field === MeasuredValue.Level) {
                    return levelUnit.value;
                }

                return MEASURED_VALUE_UNITS[props.field] ?? '';
            }
        );

        const style = computed<string|undefined>(() => {
            if (inactive.value) return;

            if (!showColoredValues.value || props.value === undefined) return;

            if (props.channel === undefined && lockedChannel.value === null) return;

            const quality = MeasurementQualityLimitsHelper.getMeasurementQuality(
                props.value,
                props.field,
                props.channel ?? lockedChannel.value ?? undefined
            );

            if (quality === undefined) return;

            const color = {
                [Quality.Good]: 'success',
                [Quality.Warning]: 'warning',
                [Quality.Danger]: 'error'
            }[quality];

            return `--measure-card-color: var(--v-${color}-base);`;
        });

        const {
            formatLevel,
            formatBER,
            formatMER,
            formatNoiseMargin,
            formatPacketErrors,
            formatPER
        } = useFormatters();

        const formattedValue = computed<string>(() => {
            if (props.value === undefined) return '-';

            switch (props.field) {
            case MeasuredValue.Level:
                return formatLevel(props.value);
            case MeasuredValue.PreBER:
            case MeasuredValue.PostBER:
                return formatBER(props.value);
            case MeasuredValue.MER:
                return formatMER(props.value);
            case MeasuredValue.NoiseMargin:
                return formatNoiseMargin(props.value);
            case MeasuredValue.PacketErrors:
                return formatPacketErrors(props.value);
            case MeasuredValue.PER:
                return formatPER(props.value);
            }

            return '-';
        });

        return {
            formattedValue,
            style,
            lockedChannel,
            inactive,
            unit
        };
    }
});
