
import { watchEffect, defineComponent, ref, watch, type PropType } from 'vue';
import { Chart as HighchartsVue } from 'highcharts-vue';
import Highcharts from 'highcharts';
import type { Options as HighchartsOptions, Chart as HighchartsChart } from 'highcharts';
import { MeasuredValue, type DatalogEntry } from 'varos-connect-shared-ts';

import boostInit from 'highcharts/modules/boost';
import { useFormatters } from '@/composables/useFormatters';

boostInit(Highcharts);

export default defineComponent({
    components: {
        Highcharts: HighchartsVue
    },
    props: {
        field: {
            type: String as PropType<MeasuredValue>,
            required: true
        },
        highlightedPoint: {
            type: Number,
            default: 0
        },
        data: {
            type: Array as PropType<DatalogEntry[]>,
            required: true
        },
        period: {
            type: Object as PropType<{ min: number, max: number }>,
            required: true
        }
    },
    setup (props, { emit }) {
        const currentValue = ref('');
        const instance = ref<HighchartsChart|null>(null);

        function highchartsCallback (chart: HighchartsChart) {
            instance.value = chart;

            // Override the reset function, we don't need to hide the tooltips and crosshairs.
            instance.value.pointer.reset = () => undefined;

            instance.value.reflow();
        }

        const { formatMER, formatBER, formatNoiseMargin, formatPacketErrors, formatPER, formatLevel } = useFormatters();

        function formatValue (val: number, withUnit?: boolean): string {
            switch (props.field) {
            case MeasuredValue.MER:
                return formatMER(val, withUnit);
            case MeasuredValue.PreBER:
            case MeasuredValue.PostBER:
                return formatBER(val, withUnit);
            case MeasuredValue.NoiseMargin:
                return formatNoiseMargin(val, withUnit);
            case MeasuredValue.PacketErrors:
                return formatPacketErrors(val, withUnit);
            case MeasuredValue.PER:
                return formatPER(val, withUnit);
            default:
                return formatLevel(val, withUnit);
            }
        }

        function calculateChartOptions (): HighchartsOptions {
            let color = getComputedStyle(document.body).getPropertyValue('--v-accent-base');
            color = color.trim();

            if (color === '') color = '#d18d1f';

            return {
                title: { text: '' },
                subtitle: { text: '' },
                legend: { enabled: false },
                credits: { enabled: false },
                tooltip: { enabled: false },
                xAxis: { title: undefined, type: 'datetime', crosshair: true, ...props.period },
                yAxis: {
                    title: undefined,
                    labels: {
                        formatter () {
                            return formatValue(this.value as number, false);
                        }
                    }
                },
                chart: {
                    height: 200,
                    animation: false,
                    marginLeft: 80
                },
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                mouseOver () {
                                    currentValue.value = this.y === undefined ? '' : formatValue(this.y, true);

                                    if (props.highlightedPoint === this.x) return;
                                    emit('update:highlightedPoint', this.x);
                                }
                            }
                        },
                        states: { hover: { enabled: false } },
                        marker: { enabled: false }
                    },
                    area: {
                        fillColor: Highcharts.color(color).setOpacity(0.2).get('rgba').toString(),
                        lineWidth: 2,
                        color,
                        turboThreshold: 0
                    }
                },
                series: [
                    {
                        id: props.field,
                        type: 'area',
                        data: []
                    }
                ]
            };
        }

        const highchartsOptions = ref<HighchartsOptions>(calculateChartOptions());
        watch([() => props.period, () => props.field], () => {
            highchartsOptions.value = calculateChartOptions();
        });

        function updateData () {
            // highcharts doesn't like if there is only one value, because
            // there is no second point to draw a line to
            if (props.data.length < 2) return;

            highchartsOptions.value.series = [
                {
                    id: props.field,
                    type: 'area',
                    data: props.data.map(x => [x.measurement_data.time, x.measurement_data[props.field]])
                }
            ];

            const lastPoint = props.data[props.data.length - 1];

            const value = lastPoint.measurement_data[props.field];
            if (value === undefined) return;
            currentValue.value = formatValue(value, true);
        }

        watch([() => props.data, () => props.field], updateData, { deep: true });
        updateData();

        // highlight new point if prop changes
        watchEffect(() => {
            if (instance.value === null) return;

            const series = instance.value.series[0];
            if (!series) return;

            const point = series.points.find(p => p.x === props.highlightedPoint);
            if (point === undefined) return;

            point.onMouseOver();
            instance.value.tooltip.refresh(point);
            instance.value.xAxis[0].drawCrosshair(undefined, point);
        });

        return {
            currentValue,
            highchartsOptions,
            highchartsCallback
        };
    }
});
