import { useEventListener as vueUseEventListener } from '@vueuse/core';
import { TypedEventTarget } from 'typescript-event-target';
import type { ValueIsType } from 'varos-connect-shared-ts/UtilityTypes';

interface TypedEventListener<M, T extends keyof M> {
    (evt: M[T]): void | Promise<void>;
}
interface TypedEventListenerObject<M, T extends keyof M> {
    handleEvent(evt: M[T]): void | Promise<void>;
}

declare type TypedEventListenerOrEventListenerObject<M, T extends keyof M> = TypedEventListener<M, T> | TypedEventListenerObject<M, T>;

export function useTypedEventListener<M extends ValueIsType<M, Event>, T extends keyof M & string> (target: TypedEventTarget<M>, event: T, listener: TypedEventListenerOrEventListenerObject<M, T>, options?: AddEventListenerOptions | boolean) {
    return vueUseEventListener(target, event, listener as (this: Window, ev: Event) => unknown, options);
}
