import { TypedEventTarget } from 'typescript-event-target';
import Responses from 'varos-connect-shared-ts/v1/responses';
import { fetchV1Response } from '.';

interface TokenServiceEventMap {
    newtoken: CustomEvent<string>;
}

export class TokenService extends TypedEventTarget<TokenServiceEventMap> {
    public static readonly instance = new TokenService();

    private _token: string|undefined;
    private promise: Promise<void>|undefined;

    private constructor () {
        super();

        // run instantly, to improve loading times
        setTimeout(() => {
            this.fetchToken().catch(() => { /* Intentionally empty */ });
        });
    }

    public fetchToken (): Promise<void> {
        if (this.promise !== undefined) return this.promise;

        this._token = undefined;

        this.promise = this.runFetch().finally(() => { this.promise = undefined; });

        return this.promise;
    }

    private async runFetch (): Promise<void> {
        const response = await fetchV1Response(
            'auth/token',
            { method: 'POST' },
            true
        );

        const json = await response.json() as Responses['POST_auth/token'];
        this._token = json.access_token;
        this.dispatchTypedEvent('newtoken', new CustomEvent('newtoken', { detail: this._token }));
    }

    public get token () {
        return this._token;
    }
}
