
import { defineComponent, ref } from 'vue';
import { computedEager } from '@vueuse/core';
import { MeasuredValue } from 'varos-connect-shared-ts';
import { socketService } from '@/services';
import { MEASURED_VALUE_UNITS, TRANSMISSION_METHOD_MEASURED_VALUES } from 'varos-connect-shared-ts/const';
import MeasureCardVue from '@/components/Global/MeasureCard.vue';
import DataloggerVue from '@/components/datalogs/Datalogger.vue';
import { useRouteStore, useSettingsStore } from '@/stores';

import { useSocketSubscription } from '@/composables/socket';
import { useTypedEventListener } from '@/composables/eventlistener';

export default defineComponent({
    components: {
        MeasureCard: MeasureCardVue,
        Datalogger: DataloggerVue
    },
    setup () {
        const edit = ref(false);

        const activeCards = ref<{ [v in MeasuredValue]: boolean }>({
            [MeasuredValue.Level]: true,
            [MeasuredValue.MER]: true,
            [MeasuredValue.PreBER]: true,
            [MeasuredValue.PostBER]: true,
            [MeasuredValue.NoiseMargin]: true,
            [MeasuredValue.PacketErrors]: true,
            [MeasuredValue.PER]: true
        });

        const helpTargets: { [key in MeasuredValue]: string } = {
            [MeasuredValue.Level]: 'Level',
            [MeasuredValue.MER]: 'MER',
            [MeasuredValue.PreBER]: 'PreBER',
            [MeasuredValue.PostBER]: 'PostBER',
            [MeasuredValue.NoiseMargin]: 'NoiseMargin',
            [MeasuredValue.PacketErrors]: 'PacketErrors',
            [MeasuredValue.PER]: 'PER'
        };

        const dataloggerShown = ref(false);

        const currentValues = ref<{ [key in MeasuredValue]: number|undefined }>({
            [MeasuredValue.Level]: undefined,
            [MeasuredValue.MER]: undefined,
            [MeasuredValue.PreBER]: undefined,
            [MeasuredValue.PostBER]: undefined,
            [MeasuredValue.NoiseMargin]: undefined,
            [MeasuredValue.PacketErrors]: undefined,
            [MeasuredValue.PER]: undefined
        });

        const routeStore = useRouteStore();
        const transmissionMethod = computedEager(() => routeStore.transmissionMethod);

        const valuesToMeasure = computedEager<MeasuredValue[]>(() => {
            if (transmissionMethod.value === null) return [];
            return TRANSMISSION_METHOD_MEASURED_VALUES[transmissionMethod.value];
        });

        useSocketSubscription('measurement');
        useTypedEventListener(socketService, 'message/measurement_data', (e) => {
            const data = e.detail.payload;

            currentValues.value[MeasuredValue.Level] = data.level;
            currentValues.value[MeasuredValue.MER] = data.mer;
            currentValues.value[MeasuredValue.PreBER] = data.pre_ber;
            currentValues.value[MeasuredValue.PostBER] = data.post_ber;
            currentValues.value[MeasuredValue.NoiseMargin] = data.noise_margin;
            currentValues.value[MeasuredValue.PacketErrors] = data.packet_errors;
            currentValues.value[MeasuredValue.PER] = data.per;
        });

        function getUnit (val: MeasuredValue): string|undefined {
            if (val === MeasuredValue.Level) {
                return useSettingsStore().getUserSetting('level_unit');
            }

            return MEASURED_VALUE_UNITS[val];
        }

        return {
            dataloggerShown,
            edit,
            valuesToMeasure,
            activeCards,
            getUnit,
            helpTargets,
            currentValues
        };
    }
});
