
import { getRemoteSupplyVoltage, setRemoteSupplyVoltage, socketService } from '@/services';
import { computed, defineComponent, ref, watch } from 'vue';
import { useSocketSubscription } from '@/composables/socket';
import { useTypedEventListener } from '@/composables/eventlistener';

export default defineComponent({
    setup () {
        const dialogShown = ref(false);
        const currentVoltage = ref(-1);
        const selectedVoltage = ref(-1);
        const voltageChoices = ref([{ value: 0, text: '0 V' }, { value: 5, text: '5 V' }, { value: 14, text: '14 V' }, { value: 18, text: '18 V' }]);

        getRemoteSupplyVoltage().then(voltage => {
            currentVoltage.value = voltage;
            selectedVoltage.value = voltage;
        });

        const publicPath: string = process.env.BASE_URL || '/';

        const measurementRunning = ref(false);
        const enabled = computed<boolean>(() => currentVoltage.value > 0);

        const iconColor = computed<string>(() => enabled.value ? '_red' : '');

        const measuredCurrent = ref(-1);
        useSocketSubscription('remote_supply_current', measurementRunning);
        useTypedEventListener(socketService, 'message/remote_supply_current', (e) => {
            if (!measurementRunning.value) return;
            measuredCurrent.value = e.detail.payload.current;
        });

        watch(enabled, (val) => {
            if (!val) measurementRunning.value = false;
        });

        watch(measurementRunning, (val) => {
            if (!val) measuredCurrent.value = -1;
        });

        watch(selectedVoltage, async (val) => {
            // TODO: Handle errors
            const newVoltage = await setRemoteSupplyVoltage(val);
            currentVoltage.value = newVoltage;
        });

        return {
            publicPath,
            measurementRunning,
            selectedVoltage,
            voltageChoices,
            iconColor,
            measuredCurrent,
            dialogShown,
            enabled
        };
    }
});
