import ConversionHelper from '@/utils/ConversionHelper';
import { useUserSetting } from './settings';

/**
 * @returns Formatter functions for different measurands
 */
export function useFormatters () {
    const separator = useUserSetting('decimal_separator');

    function replacePointWithComma (val: string): string {
        if (separator.value === ',') return val.replaceAll('.', ',');

        return val;
    }

    const levelUnit = useUserSetting('level_unit');

    function formatLevel (val: number, withUnit = false): string {
        if (levelUnit.value === 'dBµV') {
            const valStr = val.toFixed(1);
            return replacePointWithComma(withUnit ? `${valStr} dBµV` : valStr);
        }

        const valStr = ConversionHelper.dBµVTodBm(val).toFixed(1);
        return replacePointWithComma(withUnit ? `${valStr} dBm` : valStr);
    }

    function formatMER (val: number, withUnit = false): string {
        const valStr = val.toFixed(1);

        return replacePointWithComma(withUnit ? `${valStr} dB` : valStr);
    }

    const berLimit = useUserSetting('ber_limit_exponent');

    // To keep the API of all format functions the same:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function formatBER (val: number, withUnit = false): string {
        if (berLimit.value === 8 && val < 1.00e-8) return replacePointWithComma('< 1.00e-8');
        if (berLimit.value === 9 && val < 1.00e-9) return replacePointWithComma('< 1.00e-9');

        return replacePointWithComma(val.toExponential(2));
    }

    // To keep the API of all format functions the same:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function formatPER (val: number, withUnit = false): string {
        if (val < 1.00e-6) return replacePointWithComma('< 1.00e-6');

        return replacePointWithComma(val.toExponential(2));
    }

    function formatNoiseMargin (val: number, withUnit = false): string {
        const valStr = val.toFixed(1);

        return replacePointWithComma(withUnit ? `${valStr} dB` : valStr);
    }

    // To keep the API of all format functions the same:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function formatPacketErrors (val: number, withUnit = false): string {
        return Math.floor(val).toString();
    }

    /**
     * @param val Frequency (in kHz)
     * @param withUnit Return unit
     * @returns Formatted Frequency
     */
    function formatFrequency (val: number, withUnit = false): string {
        const valStr = (val / 1000).toFixed(1);

        return replacePointWithComma(withUnit ? `${valStr} MHz` : valStr);
    }

    /**
     * @param val Frequency (in kHz)
     * @param withUnit Return unit
     * @returns Formatted radio frequency
     */
    function formatRadioFrequency (val: number, withUnit = false): string {
        const valStr = (val / (1000 * 1000)).toFixed(3);

        return replacePointWithComma(withUnit ? `${valStr} GHz` : valStr);
    }

    /**
     * @param val Resolution bandwidth (in kHz)
     * @param withUnit Return unit
     * @returns Formatted Frequency
     */
    function formatRBW (val: number, withUnit = false): string {
        const rounded = Math.round(val);

        if (rounded < 1000) return withUnit ? `${rounded} kHz` : rounded.toString();

        if (rounded % 1000 === 0) return replacePointWithComma(withUnit ? `${rounded / 1000} MHz` : (rounded / 1000).toString());

        return formatFrequency(val, withUnit);
    }

    function formatBandwidth (val: number, withUnit = false): string {
        const valStr = val % 1000 === 0 ? (val / 1000).toString() : (val / 1000).toFixed(1);

        return replacePointWithComma(withUnit ? `${valStr} MHz` : valStr);
    }

    function formatSymbolRate (val: number, withUnit = false): string {
        const valStr = Math.round(val).toString();

        return withUnit ? `${valStr} kSym/s` : valStr;
    }

    // To keep the API of all format functions the same:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function formatUncommittedSwitches (val: number, withUnit = false): string {
        return '0x' + val.toString(16).padStart(2, '0').toUpperCase();
    }

    /**
     * Format a duration
     * @param duration duration (in s)
     */
    function formatDuration (durationInSeconds: number): string {
        durationInSeconds = Math.floor(durationInSeconds);
        if (durationInSeconds === 0) return '0s';

        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        let str = '';
        if (hours > 0) str += `${hours}h `;
        if (minutes > 0 || (hours > 0 && seconds > 0)) str += `${minutes}min `;
        if (seconds > 0) str += `${seconds}s`;

        return str.trim();
    }

    function formatDate (timestamp: number, options?: Intl.DateTimeFormatOptions): string {
        return new Date(timestamp).toLocaleDateString(
            undefined,
            options ?? { day: '2-digit', month: '2-digit', year: 'numeric', minute: '2-digit', hour: '2-digit' }
        );
    }

    return {
        formatLevel,
        formatMER,
        formatBER,
        formatPER,
        formatNoiseMargin,
        formatPacketErrors,

        formatFrequency,
        formatRadioFrequency,

        formatRBW,
        formatBandwidth,
        formatSymbolRate,

        formatUncommittedSwitches,

        formatDuration,
        formatDate
    };
}
