import { useSettingsStore } from '@/stores';

/**
 * @deprecated use {@link useFormatters} instead
 */
export default class Formatter {
    public static replacePointWithComma (val: string) {
        const separator = useSettingsStore().getUserSetting('decimal_separator');

        if (separator === ',') {
            return val.replaceAll('.', ',');
        }

        return val;
    }

    /**
     * @param val Frequency (in kHz)
     * @param withUnit Return unit
     * @returns Formatted Frequency
     */
    public static formatFrequency (val: number, withUnit = false): string {
        const valStr = (val / 1000).toFixed(1);

        return Formatter.replacePointWithComma(withUnit ? `${valStr} MHz` : valStr);
    }

    /**
     * @param val Frequency (in kHz)
     * @param withUnit Return unit
     * @returns Formatted radio frequency
     */
    public static formatRadioFrequency (val: number, withUnit = false): string {
        const valStr = (val / (1000 * 1000)).toFixed(3);

        return Formatter.replacePointWithComma(withUnit ? `${valStr} GHz` : valStr);
    }
}
