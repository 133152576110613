
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import HelpTopic from '@/services/types/help/HelpTopic';
import { HelpTopicService } from '@/utils';
import { mapStores } from 'pinia';
import { useHelpStore } from '@/stores';

@Component({
    computed: {
        ...mapStores(useHelpStore)
    }
})
export default class TableOfContents extends Vue {
    private helpStore!: ReturnType<typeof useHelpStore>;

    @Prop({ type: Array, default: () => [] })
    private tableOfContents!: HelpTopic[];

    @Prop({ type: Boolean, default: false })
    private fullscreenLayout!: boolean;

    private open: string[] = [];
    private active: string[] = [];

    private created () {
        const activeTopic = this.helpStore.topic;
        if (activeTopic === null) return;

        this.open = HelpTopicService.getInstance().getSuperordinateTopics(activeTopic);
        this.active = [activeTopic];
    }

    @Watch('active')
    private onActiveChanged () {
        if (this.active.length === 0) {
            // make sure user cannot unselect the currently open topic
            this.active = [this.helpStore.topic ?? 'TopicNotFound'];
            return;
        }

        const topic = this.active[0] ?? 'TopicNotFound';
        this.helpStore.changeTopic(topic);
    }

    private language: string = sessionStorage.getItem('kws-lang') ?? 'de';
}

