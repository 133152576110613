export var Cipher;
(function (Cipher) {
    Cipher["Unsupported"] = "unsupported";
    Cipher["UseGroup"] = "use group";
    Cipher["None"] = "none";
    Cipher["WEP"] = "WEP";
    Cipher["WPA"] = "WPA";
    Cipher["WPA2"] = "WPA2";
    Cipher["AES128_CMAC"] = "AES-128 CMAC";
    Cipher["NoGroup"] = "no group";
    Cipher["GCMP"] = "GCMP";
})(Cipher || (Cipher = {}));
export var AuthenticationMethod;
(function (AuthenticationMethod) {
    AuthenticationMethod["DPP"] = "DPP";
    AuthenticationMethod["FILS_SHA_256"] = "FILS/SHA-256";
    AuthenticationMethod["FILS_SHA_384"] = "FILS/SHA-384";
    AuthenticationMethod["FT_IEEE_802_1X"] = "FT/IEEE 802.1X";
    AuthenticationMethod["FT_IEEE_802_1X_SHA_384"] = "FT/IEEE 802.1X/SHA-384";
    AuthenticationMethod["FT_FILS_SHA_256"] = "FT/FILS/SHA-256";
    AuthenticationMethod["FT_FILS_SHA_384"] = "FT/FILS/SHA-384";
    AuthenticationMethod["FT_PSK"] = "FT/PSK";
    AuthenticationMethod["FT_SAE"] = "FT/SAE";
    AuthenticationMethod["IEEE_802_1X"] = "IEEE 802.1X";
    AuthenticationMethod["IEEE_802_1X_SUITE_B"] = "IEEE 802.1X/SUITE-B";
    AuthenticationMethod["IEEE_802_1X_SUITE_B_192"] = "IEEE 802.1X/SUITE-B-192";
    AuthenticationMethod["IEEE_802_1X_SHA_256"] = "IEEE 802.1X/SHA-256";
    AuthenticationMethod["OSEN"] = "OSEN";
    AuthenticationMethod["OWE"] = "OWE";
    AuthenticationMethod["PSK"] = "PSK";
    AuthenticationMethod["PSK_SHA_256"] = "PSK/SHA-256";
    AuthenticationMethod["SAE"] = "SAE";
    AuthenticationMethod["TDLS_TPK"] = "TDLS/TPK";
})(AuthenticationMethod || (AuthenticationMethod = {}));
