import { TableRow } from '@/composables/table';
import i18n from '@/plugins/i18n';
import { measure } from '@/services';
import ConversionHelper from '@/utils/ConversionHelper';
import { computed, nextTick, ref } from 'vue';
import { computedEager } from '@vueuse/core';
import { defineStore } from 'pinia';
import { LNBSettings, LockedChannel, MeasurementData, TransmissionMethod } from 'varos-connect-shared-ts';
import { useSnackbarStore, useRouteStore, useDeviceStore } from '.';
import { useOscillatorSettings } from '@/composables/settings';

interface TableLoopRun {
    status: 'running'|'aborted';
    controller: AbortController;
    id: symbol;
}

export const useTableStore = defineStore('table', () => {
    const keepMeasuring = ref(false);

    const loops = ref<TableLoopRun[]>([]);
    const currentIndex = ref(0);

    const running = computed(() => loops.value.some(({ status }) => status === 'running'));

    const routeStore = useRouteStore();
    const deviceStore = useDeviceStore();
    const transmissionMethod = computedEager(() => routeStore.transmissionMethod ?? TransmissionMethod.Cable);
    const tableRows = ref<TableRow[]>([]);

    async function loop () {
        // do not start, if there is any other loop with status "running"
        if (running.value) return;

        const defaultLNBSettings: LNBSettings = structuredClone(deviceStore.lnbSettings);

        const loopID = Symbol('table_loop');
        const controller = new AbortController();
        loops.value.push({ status: 'running', controller, id: loopID });

        controller.signal.addEventListener('abort', () => {
            loops.value = loops.value.map(x => x.id !== loopID ? x : { ...x, status: 'aborted' });
        });

        const oscillatorSettings = useOscillatorSettings();

        try {
            while (true) {
                if (controller.signal.aborted) return;

                if (currentIndex.value >= tableRows.value.length) {
                    if (keepMeasuring.value) {
                        currentIndex.value = 0;
                    } else {
                        const snackbarStore = useSnackbarStore();
                        snackbarStore.show(i18n.t('snackbar.table_done').toString(), { color: 'success' });
                        return;
                    }
                }

                const index = currentIndex.value;
                const item = tableRows.value[index].item;

                const lnb_settings = ('lnb_settings' in item ? item.lnb_settings : undefined) ?? defaultLNBSettings;

                tableRows.value.splice(index, 1, { item, index, loading: true, usedLNBSettings: lnb_settings });

                const frequency = 'radio_frequency' in item
                    ? ConversionHelper.radioFreqToIntermediateFreq(item.radio_frequency, oscillatorSettings.value, lnb_settings.if_layer)
                    : item.frequency;

                const {
                    mod_standard,
                    mod_scheme,
                    bandwidth,
                    symbol_rate,
                    plp_id,
                    afc,
                    with_docsis_modem
                } = item;

                let data: MeasurementData|undefined;
                let channel: LockedChannel|undefined;

                try {
                    const result = await measure(
                        {
                            lock_options: {
                                transmission_method: transmissionMethod.value,
                                frequency,
                                mod_standard: mod_standard === undefined ? undefined : [mod_standard],
                                mod_scheme: mod_scheme === undefined ? undefined : [mod_scheme],
                                bandwidth: bandwidth === undefined ? undefined : [bandwidth],
                                symbol_rate: symbol_rate === undefined ? undefined : [symbol_rate],
                                plp_id,
                                afc,
                                with_docsis_modem
                            },
                            lnb_settings: transmissionMethod.value === TransmissionMethod.Satellite ? lnb_settings : undefined
                        },
                        controller.signal
                    );

                    data = result.data;
                    channel = result.channel;
                    currentIndex.value++;
                } catch (err) {
                    if (err instanceof Error && err.name === 'AbortError') return;

                    // TODO: Handle other errors
                    throw err;
                } finally {
                    tableRows.value.splice(index, 1, { data, channel, item, index, loading: false, usedLNBSettings: lnb_settings });
                }
            }
        } finally {
            // delete loop right before exiting
            loops.value = loops.value.filter(({ id }) => id !== loopID);

            if (transmissionMethod.value === TransmissionMethod.Satellite) {
                // reset lnb settings, if no other loops are running
                const areOtherLoopsRunning = loops.value.some(({ status }) => status === 'running');
                if (!areOtherLoopsRunning) {
                    deviceStore.updateLNBSettings(defaultLNBSettings);
                }
            }
        }
    }

    function cancel () {
        for (const { controller } of loops.value.values()) {
            controller.abort();
        }
    }

    function restart () {
        cancel();

        currentIndex.value = 0;
        nextTick(loop);
    }

    function continueFn () {
        cancel();

        nextTick(loop);
    }

    return {
        restart,
        cancel,
        continue: continueFn,
        running,
        keepMeasuring,
        rows: tableRows
    };
});
