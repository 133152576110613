import SocketService from './socket';
import { ResponseError } from './ResponseError';
import { UnlockableFeature, ExtendedStationFIC, ExtendedStationTS, StationFIC, StationTS, Datalog, LNBSettings, MeasurementData, LockedChannel, LockOptions, DiSEqCVersion, AvailableNetwork, StoredNetwork, WifiSettings, TransmissionMethod, DOCSISDownstreamChannel, DOCSISUpstreamChannel } from 'varos-connect-shared-ts';
import type Responses from 'varos-connect-shared-ts/v1/responses';
import { DistributiveOmit, RequireAtLeastOne } from 'varos-connect-shared-ts/UtilityTypes';
import { TokenService } from './TokenService';

const API_ORIGIN = process.env.NODE_ENV === 'production' ? location.origin : `http://${location.hostname}:8000`;

const API_URL = new URL('api/', API_ORIGIN);
export const V1_API_URL = new URL('v1/', API_URL);

export const socketService = SocketService.instance;

async function fetchResponse (input: RequestInfo, options: RequestInit = {}): Promise<Response> {
    const headers = new Headers(options.headers ?? {});

    if (options.method === 'POST' || options.method === 'PUT') {
        if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');
    }

    const response = await fetch(input, { ...options, headers });

    if (!response.ok) throw new ResponseError(options.method ?? 'GET', response);

    return response;
}

export async function fetchV1Response (path: string, options: RequestInit = {}, withoutToken = false): Promise<Response> {
    const url = new URL(path, V1_API_URL).toString();

    function getOptions (): RequestInit {
        const headers = new Headers(options.headers ?? {});

        if (!headers.has('Authorization')) headers.set('Authorization', `Bearer ${TokenService.instance.token}`);

        return { ...options, headers };
    }

    // try request for the first time
    if (withoutToken || TokenService.instance.token !== undefined) {
        try {
            const response = await fetchResponse(url, getOptions());
            return response;
        } catch (err) {
            if (withoutToken) throw err;
            if (!(err instanceof ResponseError) || err.status !== 401) throw err;
        }
    }

    // At this point either ACCESS_TOKEN is undefined or the request
    // failed with status 401 -> Get new token
    await TokenService.instance.fetchToken();

    return await fetchResponse(url, getOptions());
}

async function fetchV1Void (path: string, options: RequestInit = {}): Promise<void> {
    try {
        await fetchV1Response(path, options);
    } catch (err) {
        console.error(err);
        throw err;
    }
}

async function fetchV1<T> (path: string, options: RequestInit = {}): Promise<T> {
    try {
        const response = await fetchV1Response(path, options);

        return await (response.json() as Promise<T>);
    } catch (err) {
        console.error(err);
        throw err;
    }
}

async function fetchV1ArrayBuffer (path: string, options: RequestInit = {}): Promise<ArrayBuffer> {
    try {
        const response = await fetchV1Response(path, options);

        return await response.arrayBuffer();
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const measure = async (options: { lock_options: LockOptions, lnb_settings?: LNBSettings }, signal?: AbortSignal): Promise<{ data: MeasurementData, channel?: LockedChannel }> => {
    const body: Array<{ lock_options: LockOptions, lnb_settings?: LNBSettings }> = [options];

    const res: Responses['POST_measure'] = await fetchV1('measure', { method: 'POST', body: JSON.stringify(body), signal });
    return res[0];
};

export const getDeviceInfo = async (): Promise<Responses['GET_system/device_info']> => {
    const res: Responses['GET_system/device_info'] = await fetchV1('system/device_info');

    return res;
};

export const getTimeAndDate = async (): Promise<number> => {
    const { timestamp }: Responses['GET_system/time_and_date'] = await fetchV1('system/time_and_date');
    return timestamp;
};

export const setTimeAndDate = async (timestamp: number): Promise<void> => {
    await fetchV1Void('system/time_and_date', { method: 'POST', body: JSON.stringify({ timestamp }) });
};

export const getWiFiSettings = async (): Promise<WifiSettings> => {
    const res: Responses['GET_system/wifi/settings'] = await fetchV1('system/wifi/settings');
    return res;
};

export const getAvailableWifiNetworks = async (): Promise<AvailableNetwork[]> => {
    const res: Responses['GET_system/wifi/available'] = await fetchV1('system/wifi/available');
    return res;
};

export const connectToWifiNetwork = async ({ id, ssid, mac }: RequireAtLeastOne<{ id: number, ssid: string, mac: string }>, passphrase?: string, signal?: AbortSignal): Promise<WifiSettings> => {
    const res: Responses['POST_system/wifi/connect'] = await fetchV1('system/wifi/connect', { method: 'POST', body: JSON.stringify({ id, ssid, mac, passphrase }), signal });
    return res;
};

export const getStoredWifiNetworks = async (): Promise<StoredNetwork[]> => {
    const res: Responses['GET_system/wifi/stored'] = await fetchV1('system/wifi/stored');
    return res;
};

export const createStoredWifiNetwork = async (network: DistributiveOmit<StoredNetwork, 'id' | 'mac'> & Partial<Pick<StoredNetwork, 'mac'>> & { passphrase?: string; }): Promise<StoredNetwork> => {
    const s: Responses['POST_system/wifi/stored'] = await fetchV1('system/wifi/stored', { method: 'POST', body: JSON.stringify(network) });

    return s;
};

export const updateStoredWifiNetwork = async (network: DistributiveOmit<StoredNetwork, 'ssid'|'mac'>): Promise<void> => {
    await fetchV1Void('system/wifi/stored', { method: 'PUT', body: JSON.stringify(network) });
};

export const deleteStoredWifiNetwork = async (id: number): Promise<void> => {
    await fetchV1Void('system/wifi/stored', { method: 'DELETE', body: JSON.stringify({ id }) });
};

const USER_STORAGE_BASE = 'kws_webapp';

export const saveToUserStorage = async <T>(path: string, content: T|ArrayBufferView|ArrayBuffer, contentType?: string): Promise<void> => {
    let body: BodyInit;
    if (ArrayBuffer.isView(content) || content instanceof ArrayBuffer) {
        if (contentType === undefined) contentType = 'application/octet-stream';
        body = content;
    } else {
        if (contentType === undefined) contentType = 'application/json';
        body = JSON.stringify(content);
    }

    await fetchV1Void(`storage/user/${USER_STORAGE_BASE}/${path}`, { method: 'POST', body, headers: { 'Content-Type': contentType } });
};

export async function getFromUserStorage <T> (path: string): Promise<T|undefined>;
export async function getFromUserStorage <T> (path: string, type?: 'json'): Promise<T|undefined>;
export async function getFromUserStorage (path: string, type?: 'arraybuffer'): Promise<ArrayBuffer|undefined>;
export async function getFromUserStorage <T> (path: string, type: 'arraybuffer'|'json' = 'json'): Promise<ArrayBuffer|T|undefined> {
    const url = `storage/user/${USER_STORAGE_BASE}/${path}`;

    try {
        if (type === 'arraybuffer') {
            const res = await fetchV1ArrayBuffer(url);
            return res;
        } else {
            const res = await fetchV1<T>(url);
            return res;
        }
    } catch (err) {
        if (err instanceof ResponseError || (err instanceof Error && err.name === 'ResponseError')) {
            const e = err as ResponseError;

            if (e.status === 404) return undefined;
        }

        throw err;
    }
}

export async function getStationDetails<T extends ExtendedStationFIC|ExtendedStationTS> (id: number): Promise<T> {
    const res: Responses['GET_station'] = await fetchV1(`stations/${id}`);
    return res as T;
}

export const getStationList = async (): Promise<StationTS[]|StationFIC[]> => {
    const res: Responses['GET_stations'] = await fetchV1('stations');
    return res;
};

/**
 * Get the current battery status in percent
 * @returns {Promise<number>} Promise, resolves to number
 */
export async function getBatteryStatus (): Promise<number> {
    const res: Responses['GET_battery'] = await fetchV1('battery');
    return res.battery_status;
}

export const scanUserBands = async (diseqc_version: DiSEqCVersion.JESS|DiSEqCVersion.UNICABLE): Promise<Responses['POST_scan_userbands']> => {
    const body: { diseqc_version: DiSEqCVersion.JESS|DiSEqCVersion.UNICABLE, startup_delay?: number, amount?: number } = { diseqc_version };

    const res: Responses['POST_scan_userbands'] = await fetchV1('sat/scan_userbands', { method: 'POST', body: JSON.stringify(body) });
    return res;
};

export const updateLNBSettings = async (settings: LNBSettings): Promise<Responses['POST_lnb_settings']> => {
    const res: Responses['POST_lnb_settings'] = await fetchV1('sat/lnb_settings', { method: 'POST', body: JSON.stringify(settings) });

    return res;
};

export const getUnlockableFeatures = async (): Promise<Array<{ id: UnlockableFeature, unlocked: boolean }>> => {
    const res: Responses['GET_unlockable_features'] = await fetchV1('unlockable_features');
    return res as Array<{ id: UnlockableFeature, unlocked: boolean }>;
};

export const unlockFeature = async (key: string): Promise<UnlockableFeature> => {
    const res: Responses['POST_unlockable_features'] = await fetchV1(
        'unlockable_features/unlock',
        {
            method: 'POST',
            body: JSON.stringify({ key })
        }
    );
    return res.id as UnlockableFeature;
};

export const getDatalogs = async (): Promise<Omit<Datalog, 'entries'>[]> => {
    const res: Responses['GET_datalogs'] = await fetchV1('datalogs');
    return res;
};

export const getDatalog = async (startDate: number): Promise<Datalog> => {
    const res: Responses['GET_datalog'] = await fetchV1(`datalogs/${startDate}`);
    return res;
};

export const deleteDatalog = async (startDate: number): Promise<void> => {
    await fetchV1Void(`datalogs/${startDate}`, { method: 'DELETE' });
};

export const newDatalog = async (duration: number, name?: string, location?: string): Promise<Omit<Datalog, 'entries'|'locked_channel'>> => {
    const res: Responses['POST_datalog/new'] = await fetchV1('datalogs/new', { method: 'POST', body: JSON.stringify({ duration, name, location }) });
    return res;
};

export const cancelDatalog = async (): Promise<void> => {
    return fetchV1Void('datalogs/cancel', { method: 'POST' });
};

/**
 * Get remote supply voltage
 * @returns Promise, which resolves to current voltage
 */
export const getRemoteSupplyVoltage = async (): Promise<number> => {
    const res: Responses['GET_remote_supply'] = await fetchV1('remote_supply');

    return res.voltage;
};

/**
 * Set remote supply voltage
 * @param voltage Voltage to set
 * @returns Promise, which resolves to actual new voltage
 */
export const setRemoteSupplyVoltage = async (voltage: number): Promise<number> => {
    const res: Responses['POST_remote_supply'] = await fetchV1('remote_supply', { method: 'POST', body: JSON.stringify({ voltage }) });

    return res.voltage;
};

export const startBlindScan = async (transmission_method: TransmissionMethod, min_frequency?: number, max_frequency?: number, bandwidths?: number[]): Promise<void> => {
    await fetchV1Void('blind_scan/start', { method: 'POST', body: JSON.stringify({ transmission_method, min_frequency, max_frequency, bandwidth: bandwidths }) });
};

export const cancelBlindScan = async (): Promise<void> => {
    await fetchV1Void('blind_scan/cancel', { method: 'POST' });
};

/**
 * Get details of DOCSIS downstream channel
 * @returns Promise, which resolves to current voltage
 */
export const getDOCSISDownstreamChannel = async (id: number): Promise<DOCSISDownstreamChannel> => {
    const res: Responses['GET_docsis/channel/downstream'] = await fetchV1(`docsis/channel/downstream/${id}`);

    return res;
};

/**
 * Get details of DOCSIS downstream channel
 * @returns Promise, which resolves to current voltage
 */
export const getDOCSISUpstreamChannel = async (id: number): Promise<DOCSISUpstreamChannel> => {
    const res: Responses['GET_docsis/channel/upstream'] = await fetchV1(`docsis/channel/upstream/${id}`);

    return res;
};
