import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#01488C',
                // secondary: '#d18d1f',
                accent: '#82B1FF',
                // error: '#FF5252',
                // info: '#2196F3',
                // success: '#4CAF50',
                // warning: '#FFC107',
                expertMode: '#D69F1F',
                spectrumData: '#ffb336',
                spectrumChannels: '#7ec8e3',
                spectrumMaxHold: '#d62c49',
                spectrumHighlighted: '#4169e1',
                spectrumLocked: '#8CBA80',
                spectrumAxes: '#000000'
            },
            dark: {
                primary: '#01488C',
                accent: '#82B1FF'
            }
        }
    }
});
