
import { useFormatters } from '@/composables/useFormatters';
import i18n from '@/plugins/i18n';
import { getTimeAndDate, setTimeAndDate } from '@/services';
import { useSnackbarStore } from '@/stores';
import { defineComponent, ref, watch } from 'vue';
import AbstractSetting from './AbstractSetting.vue';

export default defineComponent({
    components: { AbstractSetting },
    setup () {
        const currentTimestamp = ref<number>();
        const editTimestamp = ref(0);
        const editDialog = ref(false);
        const sending = ref(false);
        const datePickerModal = ref(false);

        async function submit () {
            sending.value = true;
            try {
                await setTimeAndDate(editTimestamp.value);
                currentTimestamp.value = editTimestamp.value;
                editDialog.value = false;
            } catch (err) {
                console.error(err);
                useSnackbarStore().show(
                    i18n.t('snackbar.failed_time_and_date_update').toString(),
                    { color: 'error' }
                );
            } finally {
                sending.value = false;
            }
        }

        getTimeAndDate().then(val => { currentTimestamp.value = val; });

        watch(editDialog, () => {
            editTimestamp.value = currentTimestamp.value ?? Date.now();
        });

        function onDatePickerInput (val: string) {
            const [year, month, date] = val.split('-').map(x => Number.parseInt(x, 10));
            const d = new Date(editTimestamp.value);
            d.setFullYear(year, month - 1, date);
            editTimestamp.value = d.getTime();
            datePickerModal.value = false;
        }

        function setMinute (val: number) {
            const d = new Date(editTimestamp.value);
            d.setMinutes(val);
            editTimestamp.value = d.getTime();
        }

        function setHour (val: number) {
            const d = new Date(editTimestamp.value);
            d.setHours(val);
            editTimestamp.value = d.getTime();
        }

        function numberRange (length: number): Array<{ text: string, value: number }> {
            const arr = new Array<{ text: string, value: number }>(length);
            for (let i = 0; i < length; i++) {
                arr[i] = { value: i, text: i.toString().padStart(2, '0') };
            }

            return arr;
        }

        const { formatDate } = useFormatters();

        return {
            currentTimestamp,
            formatDate,

            editDialog,
            editTimestamp,
            sending,
            submit,

            onDatePickerInput,
            setHour,
            setMinute,
            datePickerModal,
            numberRange
        };
    }
});
