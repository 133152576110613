import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"search-results invisible-scrollbar"},[(_vm.searchInput.length > 0)?_c(VCard,[_c(VList,{staticClass:"list elevation-1",class:{ fullscreen: _vm.isFullscreen },attrs:{"dense":"","three-line":"","inactive":""}},[_c(VDivider),_vm._l((_vm.results),function(result,index){return [_c(VListItem,{key:result.fileName,attrs:{"ripple":"","link":""},on:{"click":function($event){return _vm.openTopic(result.fileName)}}},[_c(VListItemContent,[_c('div',{staticClass:"search-result-heading"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(result.titleDE))])]),_c('p',{staticClass:"topic-content-preview"},[_vm._v(_vm._s(_vm.formatPreview(result.content)))])])],1),(index + 1 < _vm.results.length)?_c(VDivider,{key:index}):_vm._e()]}),((_vm.results.length < 1) && (_vm.searchInput.length > 1))?_c(VListItem,[_c(VListItemTitle,[_vm._v("Keine Einträge gefunden.")])],1):_vm._e()],2),(_vm.loading)?_c('kws-loading-indicator'):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }