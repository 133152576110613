import HelpTopic from '@/services/types/help/HelpTopic';
import HelpTopicService from './HelpTopicService';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FlexSearch = require('flexsearch');

export default class TopicSearchService {
    // use singleton pattern
    private static instance?: TopicSearchService;

    public static getInstance (): TopicSearchService {
        if (!this.instance) {
            this.instance = new TopicSearchService();
        }
        return this.instance;
    }

    // Array of all HelpTopics based on a json object
    private helpTopicArray: HelpTopic[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private index: any;

    private constructor () {
        this.helpTopicArray = HelpTopicService.getInstance().getHelpTopicArray();

        this.index = new FlexSearch({
            /* The entries of the Index need an id- and a field-attribute:
             * fileName and content of the HelpTopic objects are used for that
             */
            doc: {
                id: 'fileName',
                field: 'content'
            }
        });
        this.fillSearchIndex();
    }

    /**
     * Fills the Index with all helpTopics including the content
     */
    private fillSearchIndex () {
        this.helpTopicArray.forEach(async topic => {
            topic.content = await HelpTopicService.getInstance().getTopicContent(topic.fileName);
            this.index.add(topic);
        });
    }

    /**
     * Searches for a string in the content of all helpTopics and returns
     * an array of helpTopics
     * which contain the searchTerm
     * @param searchTerm the string you want to search for
     */
    public async search (searchTerm: string): Promise<HelpTopic[]> {
        const result: HelpTopic[] = await this.index.search({
            query: searchTerm
        });
        return result;
    }

    /**
     * Returns a HelpTopic object for a given fileName
     * @param fileName
     */
    public findTopic (fileName: string): HelpTopic {
        return this.index.find(fileName);
    }
}
