export var DOCSISModemStatus;
(function (DOCSISModemStatus) {
    DOCSISModemStatus["Off"] = "off";
    DOCSISModemStatus["Booting"] = "booting";
    DOCSISModemStatus["SynchronizingPHY"] = "synchronizing_phy";
    DOCSISModemStatus["AcquiringUSParams"] = "acquiring_us_params";
    DOCSISModemStatus["Ranging"] = "ranging_in_progress";
    DOCSISModemStatus["DHCPv4"] = "dhcp_in_progress";
    DOCSISModemStatus["EstablishingBPi"] = "establishing_bpi";
    DOCSISModemStatus["Operational"] = "operational";
    DOCSISModemStatus["Idle"] = "idle";
})(DOCSISModemStatus || (DOCSISModemStatus = {}));
