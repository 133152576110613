
import { defineComponent, ref, type PropType } from 'vue';
import type { ComponentSetting, Setting } from '@/services/types';
import { useUserSettingDirty } from '@/composables/settings';

export default defineComponent({
    props: {
        model: {
            type: Object as PropType<Exclude<Setting, ComponentSetting>>,
            required: true
        },
        hoverable: {
            type: Boolean,
            default: false
        },
        slotChildrenCount: {
            type: Number,
            default: 1
        }
    },
    setup (props) {
        const isDirty = 'kind' in props.model && props.model.kind === 'user' ? useUserSettingDirty(props.model.id) : ref(false);

        return {
            isDirty
        };
    }
});
