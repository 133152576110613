
import { defineComponent, ref, watch } from 'vue';
import { RawLocation } from 'vue-router';
import QueryHelper from '@/utils/QueryHelper';
import { storeToRefs } from 'pinia';
import { useRouteStore } from '@/stores';
import { getStationDetails } from '@/services';
import type { ExtendedStationTS, ExtendedStationFIC } from 'varos-connect-shared-ts';
import i18n from '@/plugins/i18n';
import { useFormatters } from '@/composables/useFormatters';
import { useRouter } from '@/composables/router';

type BreadCrumbItem = { text: string, disabled?: boolean, to: RawLocation };

export default defineComponent({
    emits: ['input'],
    watch: {
        '$route' () {
            this.updateBreadCrumbItems();
        }
    },
    setup (props, { emit }) {
        const routeStore = useRouteStore();
        const { transmissionMethod, measurementOptions, stationId, audioPID, planId } = storeToRefs(routeStore);

        const station = ref<null|ExtendedStationFIC|ExtendedStationTS>(null);
        watch(stationId, async (id) => {
            if (id === null) {
                station.value = null;
                return;
            }

            station.value = await getStationDetails(id);
        });

        const items = ref<BreadCrumbItem[]>([]);

        watch(items, e => emit('input', e));

        const router = useRouter();

        const { formatFrequency } = useFormatters();

        function updateBreadCrumbItems (): void {
            const path = router.currentRoute.path;

            // /home
            if (path.match(/^\/home\/?$/i)) {
                items.value = [{ text: i18n.t('home.title').toString(), to: '/home' }];
                return;
            }

            // /settings
            if (path.match(/^\/settings(\/.*?)?$/i)) {
                const parts = path.split('/').slice(2);
                items.value = [
                    { text: i18n.t('settings.title').toString(), to: '/settings' },
                    ...parts.map((val, index) => ({
                        text: i18n.t(`settings.name.${val}`).toString(),
                        to: `/settings/${parts.slice(0, index + 1).join('/')}`
                    }))
                ];
                return;
            }

            if (transmissionMethod.value === null) {
                items.value = [];
                return;
            }

            const arr: BreadCrumbItem[] = [];

            arr.push({
                text: i18n.t(transmissionMethod.value).toString(),
                to: {
                    path: `/${transmissionMethod.value}`,
                    query: QueryHelper.measurementOptionsToQuery({ planId: planId.value ?? undefined })
                }
            });

            if (measurementOptions.value) {
                const { frequency, display_name } = measurementOptions.value;

                const formattedFrequency = formatFrequency(frequency, true);
                const query = QueryHelper.measurementOptionsToQuery({ ...measurementOptions.value, planId: planId.value ?? undefined });

                arr.push({
                    text: display_name === undefined ? formattedFrequency : `${display_name} (${formattedFrequency})`,
                    to: { path: `/${transmissionMethod.value}/${frequency / 1000}`, query }
                });

                if (station.value !== null) {
                    arr.push({
                        text: station.value.name,
                        to: { path: `/${transmissionMethod.value}/${frequency / 1000}/station/${station.value.id}`, query }
                    });

                    if (audioPID.value !== null && station.value.type === 'video') {
                        const audioStream = station.value.audio_streams.find(({ pid }) => pid === audioPID.value);
                        if (audioStream) {
                            arr.push({
                                text: audioStream.name,
                                to: { path: `/${transmissionMethod.value}/${frequency / 1000}/stream/${station.value.id}/${audioPID.value}`, query }
                            });
                        }
                    }
                }
            }

            items.value = arr;
        }

        updateBreadCrumbItems();

        watch(
            [measurementOptions, transmissionMethod, station, audioPID, planId],
            () => updateBreadCrumbItems()
        );

        return {
            items,
            updateBreadCrumbItems
        };
    }
});
