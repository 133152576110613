
import { defineComponent, ref } from 'vue';
import { computedWithControl } from '@vueuse/core';
import RemotePowerSupplyVue from '@/components/Toolbar/RemotePowerSupply.vue';
import BreadcrumbVue from './Breadcrumb.vue';
import BatteryVue from './Battery.vue';
import { useHelpStore, useRouteStore } from '@/stores';
import type { RawLocation } from 'vue-router/types/router';
import { TransmissionMethod } from 'varos-connect-shared-ts';
import { useRouter } from '@/composables/router';

export default defineComponent({
    components: {
        Breadcrumb: BreadcrumbVue,
        RemotePowerSupply: RemotePowerSupplyVue,
        Battery: BatteryVue
    },
    setup () {
        const helpStore = useHelpStore();
        const breadcrumbItems = ref<Array<{ text: string, disabled?: boolean, to: RawLocation }>>([]);

        const router = useRouter();

        function goToHome () {
            router.push('/home');
        }

        function back () {
            if (breadcrumbItems.value.length === 1) {
                router.push('/home');
            } else if (breadcrumbItems.value.length > 1) {
                // jump to second last breadcrumb link
                router.push(breadcrumbItems.value[breadcrumbItems.value.length - 2].to);
            } else {
                router.back();
            }
        }

        function openHelp () {
            helpStore.openTopic();
        }

        const routeStore = useRouteStore();
        const showDCIcon = computedWithControl(
            () => routeStore.transmissionMethod,
            () => ([
                TransmissionMethod.Cable,
                TransmissionMethod.Antenna,
                TransmissionMethod.Radio
            ] as Array<TransmissionMethod|null>).includes(routeStore.transmissionMethod)
        );

        return {
            goToHome,
            back,
            openHelp,
            showDCIcon,
            breadcrumbItems
        };
    }
});
