import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import FourOFourVue from '@/views/404.vue';
import DataSheetVue from '@/components/Global/DataSheet.vue';

import { TransmissionMethod } from 'varos-connect-shared-ts';

Vue.use(VueRouter);

const TRANS_METHOD_REGEX = `${TransmissionMethod.Cable}|${TransmissionMethod.Satellite}|${TransmissionMethod.Antenna}|${TransmissionMethod.Radio}`;
const FREQ_REGEX = '\\d*\\.?\\d+';

const routes: Array<RouteConfig> = [
    {
        path: '*',
        component: FourOFourVue
    },
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: {
            title: 'home',
            helpTopic: 'Home'
        }
    },
    {
        path: `/:transmissionMethod(${TRANS_METHOD_REGEX})`,
        redirect: '/:transmissionMethod/spectrum', // automatically redirect to spectrum
        component: () => import(/* webpackChunkName: "transmission-method" */ '@/views/TransmissionMethod.vue'),
        children: [
            {
                path: 'spectrum',
                component: () => import(/* webpackChunkName: "transmission-method" */ '@/views/TransmissionMethod/Spectrum.vue'),
                meta: {
                    helpTopic: 'Spectrum'
                }
            },
            {
                path: 'table',
                component: () => import(/* webpackChunkName: "transmission-method" */ '@/views/TransmissionMethod/Table.vue'),
                meta: {
                    helpTopic: 'Table'
                }
            }
        ]
    },
    {
        path: `/:transmissionMethod(${TRANS_METHOD_REGEX})/:frequency(${FREQ_REGEX})`,
        redirect: '/:transmissionMethod/:frequency/data-sheet', // automatically redirect to spectrum
        component: () => import(/* webpackChunkName: "frequency" */ '@/views/Frequency.vue'),
        children: [
            {
                path: 'data-sheet',
                component: DataSheetVue
            },
            {
                path: 'constellation',
                component: () => import(/* webpackChunkName: "frequency" */ '@/views/Frequency/Constellation.vue'),
                meta: {
                    helpTopic: 'Constellation'
                }
            },
            {
                path: 'spectrum',
                component: () => import(/* webpackChunkName: "frequency" */ '@/views/Frequency/Spectrum.vue'),
                meta: {
                    helpTopic: 'Spectrum'
                }
            }
        ]
    },
    {
        path: `/:transmissionMethod(${TRANS_METHOD_REGEX})/:frequency(${FREQ_REGEX})/station/:stationId`,
        component: () => import(/* webpackChunkName: "station" */ '@/views/Station.vue'),
        meta: {
            helpTopic: 'Station'
        }
    },
    {
        path: `/:transmissionMethod(${TRANS_METHOD_REGEX})/:frequency(${FREQ_REGEX})/stream/:stationId/:audioPID`,
        component: () => import(/* webpackChunkName: "station" */ '@/views/Stream.vue')
    },
    {
        path: `/:transmissionMethod(${TRANS_METHOD_REGEX})/:frequency(${FREQ_REGEX})/docsis`,
        component: () => import(/* webpackChunkName: "docsis" */ '@/views/DOCSIS.vue')
    },
    {
        path: '/settings/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
        props: true,
        meta: {
            title: 'settings',
            helpTopic: 'Settings'
        }
    },
    {
        path: '/plans',
        component: () => import(/* webpackChunkName: "plan" */ '@/views/Plans.vue'),
        meta: {
            title: 'measurement_plans'
        }
    },
    {
        path: '/plan/create',
        component: () => import(/* webpackChunkName: "plan" */ '@/views/CreatePlan.vue')
    },
    {
        path: '/plan/:planId',
        component: () => import(/* webpackChunkName: "plan" */ '@/views/Plan.vue'),
        props: true
    },
    {
        path: '/datalogs',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Datalogs.vue'),
        meta: {
            title: 'datalogs'
        }
    },
    {
        path: '/datalogs/:startDate(\\d+)',
        props: true,
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Datalog.vue'),
        meta: {
            title: 'datalog'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
