var ModulationStandard;
(function (ModulationStandard) {
    ModulationStandard["DVB_C"] = "dvb_c";
    ModulationStandard["DOCSIS"] = "docsis";
    ModulationStandard["DVB_T"] = "dvb_t";
    ModulationStandard["DVB_T2"] = "dvb_t2";
    ModulationStandard["DVB_S"] = "dvb_s";
    ModulationStandard["DVB_S2"] = "dvb_s2";
    ModulationStandard["DVB_S2X"] = "dvb_s2x";
    ModulationStandard["DAB"] = "dab";
})(ModulationStandard || (ModulationStandard = {}));
export default ModulationStandard;
