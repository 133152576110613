
import { useRules } from '@/composables/useValidationRules';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    setup (props, { emit }) {
        const model = computed<string>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const passwordVisible = ref(false);

        const rules = useRules(computed(() => ({
            min: 8,
            max: 63,
            isRequired: props.required
        })));

        return {
            model,
            passwordVisible,
            rules
        };
    }
});
