import { utils as xlsxUtils, type WorkSheet, write } from 'xlsx';

export default class ExportHelper {
    public static exportToCSV (columns: string[], rows: string[][], name: string): File {
        const SEP = ',';

        const mustBeEscaped = (str: string) => {
            if (str.includes(SEP)) return true;
            if (str.includes('\r\n')) return true;
            if (str.includes('"')) return true;

            return false;
        };

        const str = [columns, ...rows]
            .map(row => row.map(val => {
                val = val.replaceAll('"', '""');

                if (mustBeEscaped(val)) return `"${val}"`;
                return val;
            }).join(SEP))
            .join('\r\n');

        const encoder = new TextEncoder();
        return new File(
            [encoder.encode(str)],
            `${name}.csv`,
            { type: 'text/csv;charset=utf-8;header=present' }
        );
    }

    public static exportToExcel (columns: string[], rows: string[][], name: string, additionalWorkSheets: Record<string, WorkSheet> = {}): File {
        const workbook = xlsxUtils.book_new();

        const excelData = [columns, ...rows];

        const worksheet = xlsxUtils.aoa_to_sheet(excelData);

        xlsxUtils.book_append_sheet(workbook, worksheet, 'data');

        for (const key in additionalWorkSheets) {
            if (Object.prototype.hasOwnProperty.call(additionalWorkSheets, key)) {
                const sheet = additionalWorkSheets[key];
                console.log('yoooo', sheet);
                xlsxUtils.book_append_sheet(workbook, sheet, key);
            }
        }

        const data: ArrayBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });

        return new File(
            [data],
            `${name}.xlsx`,
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        );
    }

    /**
     * Download given file
     * @param {File} file File to download
     */
    public static download (file: File): void {
        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.download = file.name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();

        // we revoke the url only after a delay because old Edge can't handle it otherwise
        window.setTimeout(() => URL.revokeObjectURL(url), 200);
    }
}
