
import { defineComponent, ref } from 'vue';
import GroupSettingVue from './GroupSetting.vue';
import AbstractSetting from './AbstractSetting.vue';
import { getDeviceInfo } from '@/services';
import Responses from 'varos-connect-shared-ts/v1/responses';

export default defineComponent({
    components: { GroupSetting: GroupSettingVue, AbstractSetting },
    setup () {
        const info = ref<Responses['GET_system/device_info']>();

        getDeviceInfo().then(val => { info.value = val; });

        // TODO: Add check for updates

        return {
            info
        };
    }
});
