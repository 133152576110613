import i18n from '@/plugins/i18n';
import { isRef, Ref, ref, unref, watch } from 'vue';
import { computedWithControl, MaybeRef } from '@vueuse/core';
import type { InputValidationRule } from 'vuetify';
import { TransmissionMethod } from 'varos-connect-shared-ts';
import { useFormatters } from './useFormatters';

type ValidationErrorID = 'required'|'invalid_format'|'too_short'|'too_long'|'not_a_number'|'not_a_integer'|'too_low'|'too_high';
type ValidationError = ValidationErrorID | { id: ValidationErrorID, params: unknown[] };

export type Rule = (val: string|number) => (true|ValidationError);

function isEmpty (val: string|number) {
    return val === null || (typeof val === 'string' && val.length === 0);
}

function isNumber (val: string|number): val is number {
    return typeof val === 'number' ||
        (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            !isNaN(val) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(val)) // ...and ensure strings of whitespace fail
        );
}

function regexRuleFactory (pattern: MaybeRef<RegExp>, error: ValidationErrorID = 'invalid_format'): Rule {
    return (val: string|number) => isEmpty(val) || unref(pattern).test(val.toString()) || error;
}

function requiredRuleFactory (error: ValidationErrorID = 'required'): Rule {
    return (val: string|number) => !isEmpty(val) || error;
}

function minLengthRuleFactory (length: MaybeRef<number>, error: ValidationErrorID = 'too_short'): Rule {
    return (val: string|number) => isEmpty(val) || val.toString().length >= unref(length) || error;
}

function maxLengthRuleFactory (length: MaybeRef<number>, error: ValidationErrorID = 'too_long'): Rule {
    return (val: string|number) => isEmpty(val) || val.toString().length <= unref(length) || error;
}

function isNumericRuleFactory (error: ValidationErrorID = 'not_a_number'): Rule {
    return (val: string|number) => isEmpty(val) || isNumber(val) || error;
}

function isIntegerRuleFactory (error: ValidationErrorID = 'not_a_integer'): Rule {
    return (val: string|number) => isEmpty(val) || (isNumber(val) && val % 1 === 0) || error;
}

function isBiggerRuleFactory (min: MaybeRef<number>, minDisplay: MaybeRef<number|string> = min, error: ValidationErrorID = 'too_low'): Rule {
    return (val: string|number) => isEmpty(val) || typeof val === 'string' || val >= unref(min) || { id: error, params: [unref(minDisplay)] };
}

function isSmallerRuleFactory (max: MaybeRef<number>, maxDisplay: MaybeRef<number|string> = max, error: ValidationErrorID = 'too_high'): Rule {
    return (val: string|number) => isEmpty(val) || typeof val === 'string' || val <= unref(max) || { id: error, params: [unref(maxDisplay)] };
}

interface RulesOptions {
    isRequired?: boolean;
    isNumber?: boolean;
    isInteger?: boolean;
    minLength?: number;
    maxLength?: number;
    min?: number | [number, number|string];
    max?: number | [number, number|string];
    pattern?: RegExp;
}

export function useRules (opts: MaybeRef<RulesOptions>): Ref<InputValidationRule[]> {
    const rules = ref<InputValidationRule[]>([]);

    function calc () {
        const r: Rule[] = [];

        const { isRequired, isNumber, isInteger, maxLength, minLength, min, max, pattern } = unref(opts);

        if (isRequired !== undefined) r.push(requiredRuleFactory());
        if (isNumber !== undefined || isInteger) r.push(isNumericRuleFactory());
        if (isInteger !== undefined) r.push(isIntegerRuleFactory());
        if (pattern !== undefined) r.push(regexRuleFactory(pattern));
        if (maxLength !== undefined) r.push(maxLengthRuleFactory(maxLength));
        if (minLength !== undefined) r.push(minLengthRuleFactory(minLength));
        if (max !== undefined) {
            const [num, display] = (typeof max === 'number' ? [max] as [number] : max);
            r.push(isSmallerRuleFactory(num, display));
        }
        if (min !== undefined) {
            const [num, display] = (typeof min === 'number' ? [min] as [number] : min);
            r.push(isBiggerRuleFactory(num, display));
        }

        rules.value = r.map((rule): InputValidationRule => val => {
            const err = rule(val);
            if (err === true) return true;

            if (typeof err === 'string') return i18n.t(`input-validation-messages.${err}`).toString();

            return i18n.t(`input-validation-messages.${err.id}`, err.params).toString();
        });
    }

    calc();

    if (isRef(opts)) watch(opts, calc);

    return rules;
}

export function useNetMaskRules () {
    return useRules({
        isRequired: true,
        pattern: /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/
    });
}

/**
 * Generate validation rules for a frequency input (in MHz)
 * @param transmissionMethod Transmission method to use
 * @param isWideband Indicates whether frequency is a wideband frequency (only used for Satellite frequencies)
 */
export function useFrequencyRules (transmissionMethod: MaybeRef<TransmissionMethod>, isWideband: MaybeRef<boolean> = false) {
    const { formatFrequency } = useFormatters();

    const options = computedWithControl<RulesOptions, unknown>(
        [transmissionMethod, isWideband].filter(isRef) as Ref<unknown>[],
        () => {
            let min: number;
            let max: number;

            switch (unref(transmissionMethod)) {
            case TransmissionMethod.Cable:
            case TransmissionMethod.Antenna:
                min = 45;
                max = 1002;
                break;
            case TransmissionMethod.Radio:
                min = 170;
                max = 250;
                break;
            case TransmissionMethod.Satellite:
                if (isWideband) {
                    min = 300; max = 2350;
                } else {
                    min = 950; max = 2150;
                }
                break;
            }

            return {
                isRequired: true,
                min: [min, formatFrequency(min * 1000, true)],
                max: [max, formatFrequency(max * 1000, true)]
            };
        }
    );

    return useRules(options);
}

/**
 * Generate validation rules for a radio frequency input (in GHz)
 */
export function useRadioFrequencyRules () {
    const { formatRadioFrequency } = useFormatters();

    return useRules({
        isRequired: true,
        min: [10.7, formatRadioFrequency(10.7 * 1000 * 1000, true)],
        max: [12.75, formatRadioFrequency(12.75 * 1000 * 1000, true)]
    });
}
