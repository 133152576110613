
import { computed, defineComponent, ref } from 'vue';
import { getBatteryStatus, socketService } from '@/services';
import { useTypedEventListener } from '@/composables/eventlistener';
import { useUserSetting } from '@/composables/settings';

export default defineComponent({
    setup () {
        const value = ref(-1);

        useTypedEventListener(socketService, 'message/battery', (e) => {
            value.value = Math.round(e.detail.payload.battery_status);
        });

        const showPercent = useUserSetting('show_battery_status_percent');

        getBatteryStatus().then((status) => {
            value.value = Math.round(status);
        });

        const style = computed(() => {
            if (value.value < 0) return '--kws-battery-percentage: 0%;';

            let style = `--kws-battery-percentage: ${value.value}%;`;

            if (value.value > 20) return style;

            style += 'font-weight: bolder;';

            if (value.value > 10) {
                style += 'color: var(--v-warning-base);';
                return style;
            }

            style += 'color: var(--v-error-base); animation-name: kws-battery-blink;';
            return style;
        });

        const text = computed(() => {
            if (value.value < 0) return '?';
            if (value.value > 20) return '';

            return '!';
        });

        return {
            style,
            showPercent,
            value,
            text
        };
    }
});
