
import { Vue, Component, Prop } from 'vue-property-decorator';
import HelpTopic from '@/services/types/help/HelpTopic';
import RelatedTopics from '@/components/Help/RelatedTopics.vue';
import { marked } from 'marked';

@Component({
    components: { RelatedTopics }
})
export default class HelpContentContainer extends Vue {
    @Prop()
    private helpContentAsMarkdown?: string;

    @Prop()
    private relatedTopics?: HelpTopic[];

    @Prop()
    private isFullscreen?: boolean;

    @Prop()
    private isGetStarted?: boolean;

    private getStartedLayout = false;

    /**
     * converts the content from markdown (.md) to html
     */
    private get content () {
        return marked(this.helpContentAsMarkdown || '');
    }

    private mounted () {
        this.getStartedLayout = this.isGetStarted || false;
    }

    private get classes () {
        const classes: string[] = [];
        if (this.isFullscreen) {
            classes.push('fullscreen');
        }
        if (this.isGetStarted) {
            classes.push('getStarted');
        }
        if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
            classes.push('device-ios');
        }
        if (navigator.userAgent.match(/android/i)) {
            classes.push('device-android');
        }

        return classes;
    }
}
